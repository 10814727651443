import styled, { css } from 'styled-components';
import { Button } from 'tdesign-react';
import { DragablePanel } from '../../control.style';

export const CallbarWrapper = styled(DragablePanel)`
  & .t-dialog {
    background: rgba(0, 14, 41, 0.88);
    backdrop-filter: blur(20px);
    border: none;
    border-radius: 10px;
    ${(props) =>
      !props.theme.isMin &&
      css`
        width: 400px !important;
        text-align: center;
        padding: 16px;
      `};
    ${(props) =>
      props.theme.isMin &&
      css`
        top: 40px;
        right: 40px;
        left: calc(100% - 200px);
        padding: 14px;
        text-align: left;
      `}
  }
`;

export const CallSizeToggleWrapper = styled.div`
  text-align: right;
`;

export const CallSizeToggle = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const CallName = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  ${(props) =>
    !props.theme.isMin &&
    css`
      margin-top: 40px;
    `}

  ${(props) =>
    props.theme.isMin &&
    css`
      width: 120px;
    `}
`;

export const CallStatus = styled.p`
  font-size: 12px;
  color: #979797;
  ${(props) =>
    !props.theme.isMin &&
    css`
      margin-top: 40px;
    `}

  ${(props) =>
    props.theme.isMin &&
    css`
      margin-top: 10px;
    `}
`;

export const CallActions = styled.div`
  text-align: center;
  ${(props) =>
    !props.theme.isMin &&
    css`
      margin-top: 61px;
    `}

  ${(props) =>
    props.theme.isMin &&
    css`
      margin-right: 20px;
      min-width: 168px;
    `}
`;

export const CallButton = styled(Button)<{ isMin: boolean }>`
  padding: 0;
  ${(props) =>
    props.theme === 'dark' &&
    css`
      background: rgba(255, 255, 255, 0.1);
    `}
  ${(props) =>
    !props.isMin &&
    css`
      width: 48px;
      height: 48px;
      border-radius: 20px;
      margin: 0 15px;
      & svg {
        width: 27px;
      }
    `}
  ${(props) =>
    props.isMin &&
    css`
      width: 36px;
      height: 36px;
      border-radius: 10px;
      margin: 0 10px;
      & svg {
        width: 20px;
      }
    `};
`;
