import { useState, useCallback, useRef } from 'react';

export function useTimer() {
  const [second, setSecond] = useState(0);
  const secondRef = useRef(second);
  const [secondTimer, setSecondTimer] = useState<number>(0);
  const [timer, setTimer] = useState('00:00');
  const startTimer = useCallback(() => {
    if (secondTimer) return;
    const interval = window.setInterval(() => {
      secondRef.current = secondRef.current + 1;
      setSecond(secondRef.current);
      setTimer(new Date(1000 * secondRef.current).toISOString().substring(11, 19));
    }, 1000);
    setSecondTimer(interval as number);
  }, [secondRef, setSecond, setTimer]);

  const stopTimer = useCallback(() => {
    clearInterval(secondTimer);
  }, [secondTimer]);

  const clearTimer = useCallback(() => {
    stopTimer();
    setTimer('00:00:00');
    setSecond(0);
    secondRef.current = 0;
  }, [stopTimer]);

  return { timer, second, startTimer, stopTimer, clearTimer };
}
