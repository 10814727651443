import React, { useCallback } from 'react';

import { ClientType } from 'src/types/common';

import { ReactComponent as DocIcon } from 'src/icons/common/doc.svg';
import { ReactComponent as LinkIcon } from 'src/icons/common/link.svg';
import { ConnectInfoWrapper, ContentItem, ContentItemTitle, ContentItemDetail } from './connectInfo.style';

interface Props {
  code?: string;
  clientType: ClientType;
}

function ConnectInfo(props: Props) {
  const { code, clientType } = props;
  const getInviteType = useCallback(() => {
    if (clientType === ClientType.master) {
      return 'request';
    } else if (clientType === ClientType.controlled) {
      return 'invite';
    }
  }, [clientType]);
  return (
    <ConnectInfoWrapper>
      <ContentItem>
        <ContentItemTitle>
          <LinkIcon /> 邀请链接
        </ContentItemTitle>
        <ContentItemDetail>
          <a>{code ? `${window.location.origin}/${getInviteType()}/${code}` : '...'}</a>
        </ContentItemDetail>
      </ContentItem>
      <ContentItem>
        <ContentItemTitle>
          <DocIcon /> 远程ID
        </ContentItemTitle>
        <ContentItemDetail>
          <strong>{code ? code : '...'}</strong>
        </ContentItemDetail>
      </ContentItem>
    </ConnectInfoWrapper>
  );
}

export { ConnectInfo };
