import React from 'react';

import { PageFooterWrapper } from './pageFooter.style';

function PageFooter() {
  return (
    <PageFooterWrapper>
      Copyright © 1998-&{new Date().getFullYear()} Tencent. All Rights Reserved.腾讯公司版权所有
    </PageFooterWrapper>
  );
}

export { PageFooter };
