import React, { useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dialog, Row, Col } from 'tdesign-react';

import { post } from 'src/requests/http';
import { ConnectMeta, ConnectMetaStatus, ClientType } from 'src/types/common';

import { ConnectInfo } from 'src/components/connect-info/ConnectInfo';

import { ReactComponent as WaitIcon } from 'src/icons/invite/wait.svg';
import { Button, WrapperWithBg } from 'src/styles/common.style';
import { InviteHeader, InviteContent, InviteActions } from './invite.style';

function Invite() {
  const navigate = useNavigate();
  const { code } = useParams();
  const [searchParams] = useSearchParams();

  const joinRoom = useCallback(async (code) => {
    try {
      await post('/invite/join', { code });
    } catch (e) {
      joinRoom(code);
    }
  }, []);

  const waitForClient = useCallback(
    async (code) => {
      try {
        const resp: ConnectMeta = await post('/polling/request', { code });
        if (resp.status === ConnectMetaStatus.connected) {
          navigate(`/control/${code}`, { replace: true });
        } else {
          waitForClient(code);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [navigate],
  );

  const handleOnOpen = useCallback(async () => {
    if (code) {
      if (searchParams.get('from') !== 'create') {
        joinRoom(code);
      }
      waitForClient(code);
    }
  }, [code, searchParams]);

  return (
    <WrapperWithBg>
      <Dialog
        visible={true}
        width={520}
        mode={'normal'}
        closeBtn={false}
        onOpened={handleOnOpen}
        header={
          <InviteHeader>
            <WaitIcon />
            等待连接
          </InviteHeader>
        }
        footer={null}
      >
        <InviteContent>
          <ConnectInfo clientType={ClientType.master} code={code} />
        </InviteContent>
        <InviteActions>
          <Row justify="center">
            <Col>
              <Button size="large">复制全部</Button>
            </Col>
          </Row>
        </InviteActions>
      </Dialog>
    </WrapperWithBg>
  );
}

export { Invite };
