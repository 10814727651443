import React from 'react';
import { Row, Col } from 'tdesign-react';
import { Link, useLocation } from 'react-router-dom';

import { InnerWrapper } from 'src/styles/common.style';

import { PageHeaderWrapper, PageTitle, PageNavItem } from './pageHeader.style';

function PageHeader() {
  const location = useLocation();
  return (
    <PageHeaderWrapper>
      <InnerWrapper>
        <Row justify="space-between" align="middle">
          <Col>
            <PageTitle>腾讯云远程</PageTitle>
          </Col>
          <Col>
            <Row justify="end" gutter={30}>
              <Col>
                <PageNavItem>
                  {location.pathname === '/' ? <Link to="/join">加入远程</Link> : <Link to="/">返回首页</Link>}
                </PageNavItem>
              </Col>
              <Col>
                <PageNavItem>联系我们</PageNavItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </InnerWrapper>
    </PageHeaderWrapper>
  );
}

export { PageHeader };
