import styled from 'styled-components';
import { Layout } from 'tdesign-react';

import { Button } from 'src/styles/common.style';

const { Content } = Layout;

export const HomeWrapper = styled(Layout)`
  background: #fff;
  min-height: 100vh;
  overflow: hidden;
`;

export const HomeContent = styled(Content)`
  flex: 1;
  display: flex;
  align-items: center;
  background-image: url('/assets/images/home/background.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 70% auto;
`;

export const ContentWrapper = styled.div``;
export const ContentTitle = styled.h2`
  font-size: 48px;
  font-family: TencentSans;
  font-weight: 700;
`;
export const ContentSubtitle = styled.h3`
  margin-top: 24px;
  font-size: 32px;
  font-family: TencentSans;
  font-weight: 700;
`;
export const ContentActions = styled.div`
  margin-top: 50px;
`;

export const ActionButton = styled(Button)`
  & span {
    margin-left: 8px;
  }
`;
