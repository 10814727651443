import styled from 'styled-components';

export const RequestContent = styled.div`
  margin-top: 16px;
`;

export const RequestActions = styled.div`
  overflow: hidden;
`;

export const DesktopInfo = styled.div``;

export const DesktopInfoItem = styled.div``;
export const ItemTitle = styled.h2`
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  & a {
    color: #006eff;
    cursor: pointer;
  }
`;
export const ItemDetail = styled.div`
  line-height: 32px;
  font-size: 16px;
  color: #999;
  & button {
    display: block;
    margin: 20px 0 30px;
  }
`;

export const OpenClientLink = styled.a`
  position: absolute;
  visibility: hidden;
`;
