import styled from 'styled-components';
import { Button as Tbutton } from 'tdesign-react';

const DEFAULT_INNER_WIDHT = 1360;

export const InnerWrapper = styled.div<{ width?: number }>`
  margin: 0 auto;
  max-width: ${(props) => (props.width ? props.width : DEFAULT_INNER_WIDHT)}px;
  flex-basis: ${(props) => (props.width ? props.width : DEFAULT_INNER_WIDHT)}px;
`;

export const WrapperWithBg = styled.div.attrs({ id: 'wrapper-with-bg' })`
  height: 100vh;
  background-image: url('/assets/images/common/background.png');
  background-size: cover;
`;

export const ProcessHeaderWithLogo = styled.h1`
  height: 40px;
  line-height: 40px;
  background-image: url('/assets/images/common/logo.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 20px;
  font-weight: 500;
  text-indent: 40px;
  color: #333;
  margin-left: -7px;
`;

export const Button = styled(Tbutton)`
  min-width: 140px;
  & span {
    font-size: 12px;
  }
`;
