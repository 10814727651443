import styled from 'styled-components';
import { Layout } from 'tdesign-react';

const { Footer } = Layout;

export const PageFooterWrapper = styled(Footer)`
  text-align: center;
  font-size: 12px;
  color: #bbb;
`;
