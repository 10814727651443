import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Steps, Row, Col } from 'tdesign-react';

import { ConnectMeta, ConnectMetaStatus } from 'src/types/common';

import { post } from 'src/requests/http';

import { Button, WrapperWithBg, ProcessHeaderWithLogo } from 'src/styles/common.style';

import { ReactComponent as SuccessIcon } from 'src/icons/create/success.svg';
import { ReactComponent as SuccessMiniIcon } from 'src/icons/create/success_mini.svg';
import { ReactComponent as DocIcon } from 'src/icons/common/doc.svg';
import { ReactComponent as LinkIcon } from 'src/icons/common/link.svg';

import { LoadingDots, StepText, StepInnerWrapper, CreateActions, CopyTip } from './create.style';

const { StepItem } = Steps;
let copyTimer: ReturnType<typeof setTimeout>;

function Create() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [copied, setCopied] = useState(false);
  const [connectInfo, setConnectInfo] = useState<ConnectMeta>({} as ConnectMeta);

  const createRequest = useCallback(async () => {
    try {
      const resp: ConnectMeta = await post('/request/new');
      setCurrent(1);
      setConnectInfo(resp);
      return Promise.resolve(resp);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const waitForClient = useCallback(
    async (code) => {
      try {
        const resp: ConnectMeta = await post('/polling/request', { code });
        setConnectInfo(resp);
        if (resp.status === ConnectMetaStatus.connected) {
          setCurrent(3);
        } else {
          waitForClient(code);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [current],
  );

  const handleCopyLinkOnClick = useCallback(async () => {
    await navigator.clipboard.writeText(
      `点击链接加入：${window.location.origin}/request/${connectInfo.code}\n输入远程ID加入：${connectInfo.code}`,
    );
    setCopied(true);
    if (current < 2) {
      setCurrent(2);
    }
    if (copyTimer) {
      clearTimeout(copyTimer);
    }
    copyTimer = setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [connectInfo, copyTimer, current]);

  const handleCancelRequest = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  const handleJoinRoomOnClick = useCallback(() => {
    let url;
    if (connectInfo.status === ConnectMetaStatus.connected) {
      url = `/control/${connectInfo.code}`;
    } else {
      url = `/invite/${connectInfo.code}/?from=create`;
    }
    window.open(url);
  }, [connectInfo]);

  const handleOnOpend = useCallback(async () => {
    const connectInfo = await createRequest();
    if (connectInfo) {
      waitForClient(connectInfo.code);
    }
  }, []);

  return (
    <WrapperWithBg>
      <Dialog
        visible={true}
        mode={'normal'}
        onOpened={handleOnOpend}
        header={<ProcessHeaderWithLogo>发起远程</ProcessHeaderWithLogo>}
        footer={null}
        closeOnEscKeydown={false}
        onCloseBtnClick={handleCancelRequest}
      >
        <Steps layout="vertical" current={current}>
          <StepItem
            icon={current > 0 ? <SuccessIcon /> : null}
            title={
              current === 0 ? (
                <>
                  创建邀请
                  <LoadingDots />
                </>
              ) : (
                '创建邀请'
              )
            }
          ></StepItem>
          <StepItem title="发送邀请" icon={current > 1 ? <SuccessIcon /> : null}>
            {current > 0 && (
              <StepInnerWrapper>
                <StepText>
                  <LinkIcon />
                  邀请对方点击链接加入：
                  <a>
                    {window.location.origin}/request/{connectInfo.code}
                  </a>
                </StepText>
                <StepText>
                  <DocIcon />
                  邀请对方通过远程ID加入：<strong>{connectInfo.code}</strong>
                </StepText>
                <Row gutter={12} align="middle">
                  <Col>
                    <Button size="large" onClick={handleCopyLinkOnClick}>
                      复制邀请
                    </Button>
                  </Col>
                  <Col>
                    <CopyTip copied={copied}>
                      <SuccessMiniIcon />
                      远程邀请已复制到剪切板
                    </CopyTip>
                  </Col>
                </Row>
              </StepInnerWrapper>
            )}
          </StepItem>
          <StepItem title={current === 3 ? '连接成功' : '连接'} icon={current > 2 ? <SuccessIcon /> : null}>
            {current > 0 && current < 3 && <StepText>等待连接...</StepText>}
          </StepItem>
        </Steps>
        <CreateActions>
          <Row gutter={16} justify="center">
            {current > 0 && current < 3 && (
              <Col>
                <Button size="large" onClick={handleJoinRoomOnClick}>
                  预先连接
                </Button>
              </Col>
            )}
            {current === 3 && (
              <Col>
                <Button size="large" onClick={handleJoinRoomOnClick}>
                  立即加入
                </Button>
              </Col>
            )}
            <Col>
              <Button
                theme="primary"
                size="large"
                variant={current === 0 ? 'base' : 'outline'}
                onClick={handleCancelRequest}
              >
                取消
              </Button>
            </Col>
          </Row>
        </CreateActions>
      </Dialog>
    </WrapperWithBg>
  );
}

export { Create };
