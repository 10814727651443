import styled from 'styled-components';

export const InviteHeader = styled.h1`
  & svg {
    vertical-align: bottom;
    margin-right: 10px;
  }
  font-size: 20px;
`;

export const InviteContent = styled.div`
  margin-top: 16px;
`;

export const InviteActions = styled.div``;
