import styled from 'styled-components';
import { Dialog } from 'tdesign-react';

export const ControlWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RemoteScreen = styled.video`
  max-width: 100%;
  max-height: 100%;
  z-index: 999;
`;

export const RemoteVoice = styled.audio``;

// common styles
export const EndControlConfirm = styled(Dialog)`
  .t-dialog {
    padding: 30px 30px 6px;
    box-sizing: border-box;
  }
`;

export const EndText = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
`;

export const EndActions = styled.div`
  margin-top: 42px;
`;

export const DragablePanel = styled(Dialog).attrs({
  draggable: true,
  destroyOnClose: true,
  mode: 'modeless',
  header: false,
  footer: false,
  closeBtn: false,
})`
  & .t-dialog {
    padding: 0;
    display: inline-block;
    width: auto !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  }
  & .t-dialog__body {
    padding: 0;
    overflow: hidden;
  }
`;
