import styled from 'styled-components';

export const StepInnerWrapper = styled.div`
  margin-top: 20px;
`;

export const StepText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #888;
  margin-bottom: 12px;
  & svg {
    vertical-align: middle;
    margin-right: 10px;
  }
  & a {
    color: #006eff;
    font-size: 12px;
  }
  & strong {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-left: 6px;
  }
`;

export const CreateActions = styled.div`
  overflow: hidden;
  padding-bottom: 1px;
`;

export const CopyTip = styled.div<{ copied: boolean }>`
  visibility: ${(props) => (props.copied ? 'visible' : 'hidden')};
  color: #000;
  & svg {
    vertical-align: -3px;
    margin-right: 12px;
  }
`;

export const LoadingDots = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--td-brand-color);
  color: var(--td-brand-color);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  &:before, &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &:before {
    left: -8px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--td-brand-color);
    color: var(--td-brand-color);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &:after {
    left: 8px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--td-brand-color);
    color: var(--td-brand-color);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
  @keyframes dot-flashing {
  0% {
    background-color: var(--td-brand-color);
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
`;
