export enum ConnectMetaType {
  request = 0,
  invite,
}

export enum ConnectMetaStatus {
  open = 0,
  connecting,
  connected,
  closed,
}

export enum ClientType {
  master = 'master',
  controlled = 'controlled',
}

export interface Monitor {
  name: string;
  width: number;
  height: number;
}

export interface ConnectMeta {
  id: number;
  code: string;
  type: ConnectMetaType;
  status: ConnectMetaStatus;
  name?: string;
  os?: string;
  createtime: string;
  requesturl?: string;
  inviteurl?: string;
  monitors?: Monitor[];
}
