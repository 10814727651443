import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Engine, Call, User } from 'rtrc-js-sdk';
import { Row, Col, Popup } from 'tdesign-react';

import { ConnectMeta } from 'src/types/common';

import { Button } from 'src/styles/common.style';

import { TOOL_TYPE } from 'src/constants/control';

import { ReactComponent as TelephoneIcon } from 'src/icons/control/telephone.svg';
import { ReactComponent as ScreenIcon } from 'src/icons/control/screen.svg';
import { ReactComponent as FullscreenIcon } from 'src/icons/control/fullscreen.svg';
import { ReactComponent as EndIcon } from 'src/icons/control/end.svg';
import { ReactComponent as MiniScreenIcon } from 'src/icons/control/mini_screen.svg';

import {
  ToolbarWrapper,
  ToolbarItem,
  DragHandle,
  ScreenItem,
  ScreenIndex,
  SelectScreenTitle,
  SclectScreenWrapper,
} from './toolbar.style';
import { EndControlConfirm, EndText, EndActions } from '../../control.style';

interface Props {
  user: User | undefined;
  fullscreenEl: React.RefObject<HTMLElement>;
  visible: boolean;
  attach: string;
  call: Call | undefined;
  engine: Engine | undefined;
  connectInfo: ConnectMeta | undefined;
  callStatus: string;
  setCallStatus: React.Dispatch<React.SetStateAction<string>>;
}

function Toolbar(props: Props) {
  const { user, fullscreenEl, visible, attach, call, engine, callStatus, setCallStatus, connectInfo } = props;
  const [isEnding, setIsEnding] = useState(false);
  const [selectScreenVisible, setSelectScreenVisible] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);
  const navigate = useNavigate();

  const handleSclectScreenOnClick = useCallback(
    (index) => {
      setSelectScreenVisible(false);
      if (index === currentScreen) return;
      if (engine && user) {
        setCurrentScreen(index);
        engine.selectScreen(index, user);
      }
    },
    [engine, currentScreen, user],
  );

  const renderSelectScreen = useCallback(() => {
    const monitors = connectInfo?.monitors || [{}];
    return (
      <SclectScreenWrapper>
        <Row gutter={12} align="middle">
          <Col>
            <SelectScreenTitle>选择屏幕</SelectScreenTitle>
          </Col>
          {monitors.map((monitors, index) => (
            <Col key={index} onClick={() => handleSclectScreenOnClick(index)}>
              <ScreenItem selected={index === currentScreen}>
                <MiniScreenIcon />
                <ScreenIndex>{index + 1}</ScreenIndex>
              </ScreenItem>
            </Col>
          ))}
        </Row>
      </SclectScreenWrapper>
    );
  }, [connectInfo, handleSclectScreenOnClick]);

  const handleMouseDownOnToolBarItem = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleTelephoneOnClick = useCallback(() => {
    if (engine && call && [Call.NEW, Call.DISCONNECTED].includes(callStatus)) {
      engine.makeCall(call);
      setCallStatus(Call.CALLING_OUT);
    }
  }, [engine, call, callStatus]);

  const handleScreenOnClick = useCallback(() => {
    setSelectScreenVisible(!selectScreenVisible);
  }, [selectScreenVisible]);

  const handleFullscreenOnClick = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFullscreen(false);
    } else {
      fullscreenEl.current?.requestFullscreen();
      setIsFullscreen(true);
    }
  }, [fullscreenEl]);

  const handleEndOnClick = useCallback(() => {
    setIsEnding(true);
  }, []);

  const handleEndRemote = useCallback(() => {
    engine?.close();
    navigate('/', { replace: true });
  }, [engine]);

  return (
    <ToolbarWrapper visible={visible} attach={attach}>
      <Row>
        <Col>
          <DragHandle></DragHandle>
        </Col>
        <Col>
          <ToolbarItem
            className={TOOL_TYPE.TELEPHONE}
            onMouseDown={handleMouseDownOnToolBarItem}
            onClick={handleTelephoneOnClick}
          >
            <TelephoneIcon />
            电话
          </ToolbarItem>
        </Col>
        <Col>
          <Popup
            attach={attach}
            visible={selectScreenVisible}
            placement="bottom-left"
            showArrow={false}
            content={renderSelectScreen()}
          >
            <ToolbarItem
              className={TOOL_TYPE.SCREEN}
              onMouseDown={handleMouseDownOnToolBarItem}
              onClick={handleScreenOnClick}
            >
              <ScreenIcon />
              屏幕
            </ToolbarItem>{' '}
          </Popup>
        </Col>
        <Col>
          <ToolbarItem
            className={TOOL_TYPE.FULLSCREEN}
            onMouseDown={handleMouseDownOnToolBarItem}
            onClick={handleFullscreenOnClick}
          >
            <FullscreenIcon />
            {isFullscreen ? '退出全屏' : '全屏'}
          </ToolbarItem>
        </Col>
        <Col>
          <ToolbarItem className={TOOL_TYPE.END} onMouseDown={handleMouseDownOnToolBarItem} onClick={handleEndOnClick}>
            <EndIcon />
            结束
          </ToolbarItem>
        </Col>
      </Row>
      <EndControlConfirm
        attach={attach}
        visible={isEnding}
        header={'结束远程访问'}
        closeBtn={false}
        footer={null}
        width={368}
      >
        <EndText>您确定要结束远程访问吗</EndText>
        <EndActions>
          <Row justify="space-around">
            <Col>
              <Button size="large" onClick={handleEndRemote}>
                确定
              </Button>
            </Col>
            <Col>
              <Button size="large" variant="outline" theme="primary" onClick={() => setIsEnding(false)}>
                取消
              </Button>
            </Col>
          </Row>
        </EndActions>
      </EndControlConfirm>
    </ToolbarWrapper>
  );
}

export { Toolbar };
