import styled, { css } from 'styled-components';

import { DragablePanel } from '../../control.style';

export const ToolbarWrapper = styled(DragablePanel)`
  .t-dialog {
    top: 40px;
  }
`;

export const DragHandle = styled.div`
  width: 12px;
  height: 56px;
  background: rgb(241, 243, 244);
`;

export const ToolbarItem = styled.div`
  width: 56px;
  margin: 10px 0;
  text-align: center;
  font-size: 10px;
  color: #888;
  cursor: pointer;
  & svg {
    display: block;
    margin: 0 auto 6px;
    stroke: #363d4d;
    fill: #363d4d;
  }
  &.setting svg {
    margin: -4px auto 2px;
  }
  &.telephone {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  &.end {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  &:hover {
    svg {
      stroke: #006eff;
      fill: #006eff;
    }
  }
`;

export const SclectScreenWrapper = styled.div`
  padding: 6px 2px;
`;
export const ScreenItem = styled.div<{ selected: boolean }>`
  position: relative;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      color: #006eff;
      stroke: #006eff;
    `}

  ${(props) =>
    !props.selected &&
    css`
      color: #999;
      stroke: #999;
    `}
`;
export const ScreenIndex = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.5);
`;

export const SelectScreenTitle = styled.h3`
  color: #999;
  font-size: 10px;
  margin-right: 8px;
`;
