import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageHeader } from 'src/components/page-header/PageHeader';
import { PageFooter } from 'src/components/page-footer/PageFooter';
import { Button } from 'src/styles/common.style';

import {
  JoinWrapper,
  JoinContent,
  ContentTitle,
  ContentSubtitle,
  JoinInputBox,
  InputTitle,
  InputText,
} from './join.style';

function Join() {
  const [remoteId, setRemoteId] = useState('');
  const navigate = useNavigate();

  const handleRemoteIdOnChange = useCallback((id) => {
    let i = 0;
    let formatIdItem = '';
    const originId = id.split('-').join('').substring(0, 6);
    console.log(originId);
    const idArr: string[] = originId.split('');
    const formatIdArr = [];
    idArr.forEach((item) => {
      formatIdItem = formatIdItem + item;
      i++;
      if (i === 3) {
        formatIdArr.push(formatIdItem);
        formatIdItem = '';
        i = 0;
      }
    });
    if (formatIdItem) {
      formatIdArr.push(formatIdItem);
    }
    setRemoteId(formatIdArr.join('-'));
  }, []);

  const handleJoinRemoteOnClick = useCallback(() => {
    navigate(`/request/${remoteId.split('-').join('')}`);
  }, [navigate, remoteId]);

  return (
    <JoinWrapper>
      <PageHeader />
      <JoinContent>
        <ContentTitle>腾讯云远程</ContentTitle>
        <ContentSubtitle>打开网页，即刻开启您的远程之旅</ContentSubtitle>
        <JoinInputBox>
          <InputTitle>请输入您的邀请远程ID</InputTitle>
          <InputText value={remoteId} theme="normal" placeholder="000-000" onChange={handleRemoteIdOnChange} />
          <Button disabled={remoteId.length !== 7} size="large" onClick={handleJoinRemoteOnClick}>
            加入远程
          </Button>
        </JoinInputBox>
      </JoinContent>
      <PageFooter />
    </JoinWrapper>
  );
}

export { Join };
