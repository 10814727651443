import axios, { AxiosRequestConfig } from 'axios';

export interface RequestConfig extends AxiosRequestConfig {
  version?: string;
  successCode?: number | string;
}

const BASE_URL = 'https://remott.woa.com/';
const DEFAULT_API_VERSION = 'v1';
const DEFAULT_SUCCESS_CODE = 0;

function getUrl(url: RequestConfig['url'], version: RequestConfig['version']) {
  if (version) {
    return `/${version}${url}`;
  }
  return `/${DEFAULT_API_VERSION}${url}`;
}

async function baseRequest(
  url: RequestConfig['url'],
  method: RequestConfig['method'],
  params: RequestConfig['params'],
  data: RequestConfig['data'],
  options: Omit<RequestConfig, 'url' | 'data' | 'method' | 'params'> = {},
) {
  let successCode;
  successCode = DEFAULT_SUCCESS_CODE;
  if (options.successCode !== undefined) {
    successCode = options.successCode;
  }
  try {
    const resp = await axios({
      url: getUrl(url, options.version),
      method,
      params,
      data,
      baseURL: BASE_URL,
      ...options,
    });

    if (resp.data.code === successCode) {
      return Promise.resolve(resp.data.data);
    } else {
      return Promise.reject(resp.data);
    }
  } catch (e) {
    return Promise.reject(e);
  }
}

function post(
  url: RequestConfig['url'],
  data?: RequestConfig['data'],
  options: Omit<RequestConfig, 'url' | 'data'> = {},
) {
  const params = options.params;
  delete options.params;
  return baseRequest(url, 'post', params, data, options);
}

function get(
  url: RequestConfig['url'],
  params: RequestConfig['params'],
  options: Omit<RequestConfig, 'url' | 'params'>,
) {
  const data = options.data;
  delete options.data;
  return baseRequest(url, 'get', params, data, options);
}

export { get, post };
