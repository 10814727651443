import React, { useState, useEffect, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import { Row, Col } from 'tdesign-react';
import { Call, Engine } from 'rtrc-js-sdk';

import {
  CallbarWrapper,
  CallName,
  CallStatus,
  CallSizeToggleWrapper,
  CallSizeToggle,
  CallActions,
  CallButton,
} from './callbar.style';
import { useTimer } from 'src/hooks';
import { ReactComponent as FullscreenCallIcon } from 'src/icons/control/fullscreen_call.svg';
import { ReactComponent as ExitFullscreenCallIcon } from 'src/icons/control/exit_fullscreen_call.svg';
import { ReactComponent as MikeIcon } from 'src/icons/control/mike.svg';
import { ReactComponent as MikeMuteIcon } from 'src/icons/control/mike_mute.svg';
import { ReactComponent as VolumeIcon } from 'src/icons/control/volume.svg';
import { ReactComponent as VolumeMuteIcon } from 'src/icons/control/volume_mute.svg';
import { ReactComponent as AnswerCallIcon } from 'src/icons/control/answer_call.svg';
import { ReactComponent as HangUpIcon } from 'src/icons/control/hang_up.svg';

interface Props {
  call: Call | undefined;
  engine: Engine | undefined;
  callStatus: string;
  attach: string;
  setCallStatus: React.Dispatch<React.SetStateAction<string>>;
  connectVoice: () => void;
  disconnectVoice: () => void;
  isVolumeDisabled: boolean;
  setIsVolumeDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

function Callbar(props: Props) {
  const [theme, setTheme] = useState({ isMin: true });
  const [itemSpan, setItemSpan] = useState(theme.isMin ? null : 12);
  const [isMikeDisabled, setIsMikeDisabled] = useState(false);
  const { timer, startTimer, clearTimer } = useTimer();

  const {
    call,
    attach,
    engine,
    callStatus,
    setCallStatus,
    connectVoice,
    disconnectVoice,
    isVolumeDisabled,
    setIsVolumeDisabled,
  } = props;

  useEffect(() => {
    setItemSpan(theme.isMin ? null : 12);
  }, [theme.isMin]);

  const handleToggleCallBarSize = useCallback(() => {
    setTheme({
      isMin: !theme.isMin,
    });
  }, [theme.isMin]);

  const handleHangUp = useCallback(() => {
    if (engine && call) {
      engine.endCall(call);
      setCallStatus(Call.DISCONNECTED);
    }
  }, [engine, call]);

  const handleAnswerCall = useCallback(() => {
    if (engine && call) {
      engine.answerCall(call);
      setCallStatus(Call.CONNECTED);
    }
  }, [engine, call]);

  const handleToggleMike = useCallback(() => {
    if (isMikeDisabled) {
      connectVoice();
    } else {
      disconnectVoice();
    }
    setIsMikeDisabled(!isMikeDisabled);
  }, [isMikeDisabled, connectVoice, disconnectVoice]);

  const handleToggleVolume = useCallback(() => {
    setIsVolumeDisabled(!isVolumeDisabled);
  }, [isVolumeDisabled]);

  useEffect(() => {
    switch (callStatus) {
      case Call.CALLING_IN:
        setTheme({ isMin: true });
        break;
      case Call.CALLING_OUT:
        setTheme({ isMin: false });
        break;
      case Call.CONNECTED:
        startTimer();
        break;
      case Call.DISCONNECTED:
        clearTimer();
        setIsMikeDisabled(false);
        setIsVolumeDisabled(false);
    }
  }, [callStatus]);

  return (
    <ThemeProvider theme={theme}>
      <CallbarWrapper
        attach={attach}
        visible={[Call.CALLING_IN, Call.CALLING_OUT, Call.CONNECTED].includes(callStatus)}
      >
        <Row align="middle">
          <Col span={itemSpan} order={theme.isMin ? 3 : null}>
            <CallSizeToggleWrapper>
              <CallSizeToggle onClick={handleToggleCallBarSize}>
                {theme.isMin ? <FullscreenCallIcon /> : <ExitFullscreenCallIcon />}
              </CallSizeToggle>
            </CallSizeToggleWrapper>
          </Col>
          <Col span={itemSpan}>
            <CallName>远程连接</CallName>
            <CallStatus>
              {callStatus === Call.CALLING_IN && '邀请您语音通话...'}
              {callStatus === Call.CALLING_OUT && '等待接听中...'}
              {callStatus === Call.CONNECTED && `通话中：${timer}`}
            </CallStatus>
          </Col>
          <Col span={itemSpan}>
            <CallActions>
              {callStatus === Call.CONNECTED && (
                <>
                  <CallButton
                    theme="dark"
                    isMin={theme.isMin}
                    icon={isMikeDisabled ? <MikeMuteIcon /> : <MikeIcon />}
                    onClick={handleToggleMike}
                  />
                  <CallButton
                    theme="dark"
                    isMin={theme.isMin}
                    icon={isVolumeDisabled ? <VolumeMuteIcon /> : <VolumeIcon />}
                    onClick={handleToggleVolume}
                  />
                </>
              )}
              <CallButton theme="danger" isMin={theme.isMin} icon={<HangUpIcon />} onClick={handleHangUp} />
              {callStatus === Call.CALLING_IN && (
                <CallButton theme="success" isMin={theme.isMin} icon={<AnswerCallIcon />} onClick={handleAnswerCall} />
              )}
            </CallActions>
          </Col>
        </Row>
      </CallbarWrapper>
    </ThemeProvider>
  );
}

export { Callbar };
