import styled from 'styled-components';
import { Layout, Input } from 'tdesign-react';

const { Content } = Layout;

export const JoinWrapper = styled(Layout)`
  height: 100vh;
  background-image: url('/assets/images/join/background.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  overflow: hidden;
  background-color: #fff;
`;

export const JoinContent = styled(Content)`
  flex: 1;
  text-align: center;
`;

export const ContentTitle = styled.h2`
  font-size: 48px;
  font-family: TencentSans;
  font-weight: 700;
  margin-top: 80px;
`;
export const ContentSubtitle = styled.h3`
  margin-top: 24px;
  font-size: 32px;
  font-family: TencentSans;
  font-weight: 700;
`;

export const JoinInputBox = styled.div`
  margin-top: 70px;
`;

export const InputTitle = styled.p`
  font-size: 12px;
  color: #999;
`;

export const InputText = styled(Input)`
  display: block;
  width: auto;
  margin: 16px auto 26px;
  & .t-input__inner {
    font-size: 36px;
    font-weight: 500;
    border: none !important;
    box-shadow: none !important;
    width: auto;
    text-align: center;
    outline: none;
  }
`;
