import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';

import { Home } from './pages/home/Home';
import { Join } from './pages/join/Join';
import { Create } from './pages/create/Create';
import { Request } from './pages/request/Request';
import { Invite } from './pages/invite/Invite';
import { Control } from './pages/control/Control';

function Routes() {
  return (
    <ReactRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/join" element={<Join />} />
      <Route path="/create" element={<Create />} />
      <Route path="/request/:code" element={<Request />} />
      <Route path="/request/" element={<Request />} />
      <Route path="/invite/:code" element={<Invite />} />
      <Route path="/control/:code" element={<Control />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </ReactRoutes>
  );
}

export { Routes };
