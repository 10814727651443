import styled from 'styled-components';
import { Layout } from 'tdesign-react';

const { Header } = Layout;

export const PageHeaderWrapper = styled(Header)`
  margin: 30px 10px 0;
  height: auto;
  background: transparent;
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  background-position: left center;
  text-indent: 60px;
  background-image: url('/assets/images/common/logo.png');
  background-size: 60px 60px;
  height: 60px;
  line-height: 60px;
  background-repeat: no-repeat;
`;

export const PageNavItem = styled.span`
  font-size: 16px;
  color: #666;
  cursor: pointer;
`;
