import styled from 'styled-components';

export const ConnectInfoWrapper = styled.div``;
export const ContentItem = styled.div`
  margin-bottom: 24px;
`;
export const ContentItemTitle = styled.h2`
  & svg {
    vertical-align: bottom;
    margin-right: 10px;
  }
  font-size: 14px;
  color: #666;
`;
export const ContentItemDetail = styled.div`
  margin-top: 12px;
  & strong {
    font-size: 24px;
    font-weight: 700;
    color: #000;
  }

  & a {
    font-size: 16px;
    color: #006eff;
  }
`;
