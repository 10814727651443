import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Loading, Row, Col } from 'tdesign-react';
import { useParams, useNavigate } from 'react-router-dom';
import { Engine, RemoteTrack, LocalTrack, Call, User } from 'rtrc-js-sdk';

import { ConnectMeta } from 'src/types/common';
import { post } from 'src/requests/http';
import { Button } from 'src/styles/common.style';

import { Toolbar } from './components/toolbar/Toolbar';
import { Callbar } from './components/callbar/Callbar';

import { ControlWrapper, RemoteScreen, RemoteVoice, EndControlConfirm, EndActions } from './control.style';

const DEFAULT_SDK_APP_ID = 1111;
const RELAY_SERVER_BASE_URL = 'https://remott.woa.com';
const RELAY_SERVER_HTTP_URL = `${RELAY_SERVER_BASE_URL}/v1/post`;
const RELAY_SERVER_SSE_URL = `${RELAY_SERVER_BASE_URL}/v1/channel`;
const RELAY_SERVER_SUCCESS_CODE = 10000;

function Control() {
  const { code } = useParams();
  const navigate = useNavigate();
  const remoteScreenEl = useRef<HTMLVideoElement>(null);
  const remoteVoiceEl = useRef<HTMLAudioElement>(null);
  const controlEl = useRef<HTMLDivElement>(null);
  const [isWaiting, setIsWaiting] = useState(true);
  const [isEnding, setIsEnding] = useState(false);
  const [call, setCall] = useState<Call>();
  const [engine, setEngine] = useState<Engine>();
  const [callStatus, setCallStatus] = useState(Call.NEW);
  const [user, setUser] = useState<User>();
  const [localAudioTrack, setLocalAudioTrack] = useState<LocalTrack | null>(null);
  const [isVolumeDisabled, setIsVolumeDisabled] = useState(false);
  const [connectInfo, setConnectInfo] = useState<ConnectMeta>();

  const sdkappid = DEFAULT_SDK_APP_ID;
  const roomid = code || '';
  const userid = Math.random().toString(36).substring(7);

  const connectVoice = useCallback(async () => {
    if (engine && user) {
      const track = new LocalTrack(user.userid, 'audio');
      await track.init();
      engine.publish(track);
      setLocalAudioTrack(track);
      return track;
    }
    return null;
  }, [engine, user]);

  const disconnectVoice = useCallback(() => {
    if (localAudioTrack && engine) {
      localAudioTrack.stop();
      engine.unpublish(localAudioTrack);
      setLocalAudioTrack(null);
    }
  }, [localAudioTrack, engine]);

  const requestConnectionInfo = useCallback(async () => {
    try {
      const resp: ConnectMeta = await post('/request/one', { code: roomid });
      setConnectInfo(resp);
    } catch (e) {
      console.log(e);
    }
  }, [roomid]);

  const requestConnectionToken = useCallback(async () => {
    try {
      const { token } = await post(
        '/testtoken',
        { sdkappid, roomid, userid },
        { baseURL: RELAY_SERVER_BASE_URL, successCode: RELAY_SERVER_SUCCESS_CODE },
      );
      return token;
    } catch (e) {
      console.log(e);
    }
  }, [sdkappid, roomid, userid]);

  const setupConnection = useCallback(async () => {
    const token = await requestConnectionToken();

    const engine = new Engine({ httpurl: RELAY_SERVER_HTTP_URL, signalingurl: RELAY_SERVER_SSE_URL });

    setEngine(engine);

    await engine.join({
      sdkappid,
      roomid,
      userid,
      token,
    });

    engine.on(Engine.UserJoinEvent, async (user) => {
      console.log('user joined', user);
      requestConnectionInfo();
      setUser(user);
    });

    engine.on(Engine.UserLeaveEvent, async (user) => {
      console.log('user leaved', user);
      setIsEnding(true);
      setIsWaiting(false);
    });

    engine.on(Engine.TrackPublishEvent, async (track: RemoteTrack) => {
      console.log('user publish ', track);
      await engine.subscribe(track);
      if (track.mediatype === 'screen') {
        if (remoteScreenEl.current) {
          track.play(remoteScreenEl.current);
          remoteScreenEl.current.onloadedmetadata = async () => {
            await engine.receiveFirstVideoFrame(track.userid);
            await engine.publishInput(remoteScreenEl.current as HTMLElement, track);
            setIsWaiting(false);
          };
        }
      } else if (track.mediatype === 'audio') {
        if (remoteVoiceEl.current) {
          track.play(remoteVoiceEl.current);
        }
      }
    });

    engine.on(Engine.TrackUnpublishEvent, async (track: RemoteTrack) => {
      console.log('user unpublish', track);
      engine.unsubscribe(track);
    });
  }, [sdkappid, roomid, userid, remoteScreenEl, remoteVoiceEl, connectVoice, disconnectVoice]);

  const handleEndRemote = useCallback(() => {
    engine && engine.close();
    navigate('/', { replace: true });
  }, [engine, navigate]);

  useEffect(() => {
    setupConnection();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();
      engine?.close();
    });
  }, [engine]);

  useEffect(() => {
    switch (callStatus) {
      case Call.CONNECTED:
        connectVoice();
        break;
      case Call.DISCONNECTED:
        disconnectVoice();
        break;
    }
  }, [callStatus]);

  useEffect(() => {
    (async () => {
      if (engine && user) {
        const call = await engine.createCall(user.userid);
        setCall(call);
        call.on('message', async (callEventType) => {
          setCallStatus(callEventType);
        });
      }
    })();
  }, [engine, user]);

  return (
    <ControlWrapper ref={controlEl} id="control-wrapper">
      <RemoteScreen ref={remoteScreenEl} />
      <RemoteVoice ref={remoteVoiceEl} muted={isVolumeDisabled} />

      <Toolbar
        user={user}
        fullscreenEl={controlEl}
        visible={!isWaiting}
        attach={'#control-wrapper'}
        call={call}
        callStatus={callStatus}
        engine={engine}
        connectInfo={connectInfo}
        setCallStatus={setCallStatus}
      />
      <Callbar
        call={call}
        engine={engine}
        attach={'#control-wrapper'}
        callStatus={callStatus}
        isVolumeDisabled={isVolumeDisabled}
        setIsVolumeDisabled={setIsVolumeDisabled}
        setCallStatus={setCallStatus}
        connectVoice={connectVoice}
        disconnectVoice={disconnectVoice}
      />
      <Loading loading={isWaiting} fullscreen preventScrollThrough={true}></Loading>
      <EndControlConfirm
        attach={'#control-wrapper'}
        visible={isEnding}
        header={
          <Row justify="center">
            <Col>对方已结束远程</Col>
          </Row>
        }
        closeBtn={false}
        footer={null}
        width={368}
      >
        <EndActions>
          <Row justify="center">
            <Col>
              <Button size="large" onClick={handleEndRemote}>
                结束
              </Button>
            </Col>
          </Row>
        </EndActions>
      </EndControlConfirm>
    </ControlWrapper>
  );
}

export { Control };
