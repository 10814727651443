import React, { useCallback } from 'react';
import { Row, Col } from 'tdesign-react';
import { useNavigate } from 'react-router-dom';

import { PageHeader } from 'src/components/page-header/PageHeader';
import { PageFooter } from 'src/components/page-footer/PageFooter';

import { InnerWrapper } from 'src/styles/common.style';
import { ReactComponent as RequestIcon } from 'src/icons/home/request.svg';
import { ReactComponent as InviteIcon } from 'src/icons/home/invite.svg';

import {
  HomeWrapper,
  HomeContent,
  ContentWrapper,
  ContentTitle,
  ContentSubtitle,
  ContentActions,
  ActionButton,
} from './home.style';

function Home() {
  const navigate = useNavigate();
  const handleRequestOnClick = useCallback(() => {
    navigate('/create');
  }, []);
  const handleInviteOnClick = useCallback(() => {
    navigate('/request');
  }, []);

  return (
    <HomeWrapper>
      <PageHeader />
      <HomeContent>
        <InnerWrapper width={1120}>
          <ContentWrapper>
            <ContentTitle>腾讯云远程</ContentTitle>
            <ContentSubtitle>打开网页，即刻开启您的远程之旅！</ContentSubtitle>
            <ContentActions>
              <Row gutter={12}>
                <Col>
                  <ActionButton
                    theme="primary"
                    variant="base"
                    size="large"
                    icon={<RequestIcon />}
                    onClick={handleRequestOnClick}
                  >
                    发起远程控制
                  </ActionButton>
                </Col>
                <Col>
                  <ActionButton
                    theme="primary"
                    variant="outline"
                    size="large"
                    icon={<InviteIcon />}
                    onClick={handleInviteOnClick}
                  >
                    请求远程协助
                  </ActionButton>
                </Col>
              </Row>
            </ContentActions>
          </ContentWrapper>
        </InnerWrapper>
      </HomeContent>
      <RequestIcon />
      <PageFooter />
    </HomeWrapper>
  );
}

export { Home };
