import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Dialog, Row, Col } from 'tdesign-react';

import { post } from 'src/requests/http';

import { ConnectInfo } from 'src/components/connect-info/ConnectInfo';

import { ConnectMeta, ClientType } from 'src/types/common';

import { Button, WrapperWithBg, ProcessHeaderWithLogo } from 'src/styles/common.style';
import {
  RequestContent,
  RequestActions,
  DesktopInfo,
  DesktopInfoItem,
  ItemTitle,
  ItemDetail,
  OpenClientLink,
} from './request.style';

const CLIENT_DOWNLOAD_URL = 'https://test-1300762101.cos.ap-beijing.myqcloud.com/TRemoteDesk.msi';

function Request() {
  const { code } = useParams();
  const navigate = useNavigate();
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestCode, setRequestCode] = useState(code);
  const openClientEl = useRef<HTMLAnchorElement>(null);

  const createRequest = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp: ConnectMeta = await post('/request/new');
      setRequestCode(resp.code);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }, []);

  const handleCopyLinkOnClick = useCallback(async () => {
    await navigator.clipboard.writeText(
      `点击链接加入：${window.location.origin}/invite/${requestCode}\n输入远程ID加入：${requestCode}`,
    );
  }, [requestCode]);

  const handleOnClose = useCallback(async () => {
    navigate('/');
  }, [navigate]);

  const handleDownloadOnClick = useCallback(async () => {
    const download = document.createElement('a');
    download.href = CLIENT_DOWNLOAD_URL;
    download.target = '_blank';
    download.download = 'TRemoteDesk';
    download.click();
  }, []);

  useEffect(() => {
    if (code || isJoined) {
      openClientEl.current?.click();
    }
  }, [isJoined, openClientEl, code]);

  useEffect(() => {
    if (code === undefined) {
      createRequest();
    }
  }, [code]);

  return (
    <WrapperWithBg>
      <Dialog
        visible={true}
        mode={'normal'}
        header={<ProcessHeaderWithLogo>腾讯云远程</ProcessHeaderWithLogo>}
        footer={null}
        onClose={handleOnClose}
      >
        <RequestContent>
          {!isJoined && !code && <ConnectInfo clientType={ClientType.controlled} code={requestCode} />}
          {(isJoined || code) && (
            <DesktopInfo>
              <DesktopInfoItem>
                <ItemTitle>该页面会唤起云远程客户端</ItemTitle>
                <ItemDetail>
                  *若无法正常跳转请先点击下方【立即下载】按钮
                  <Button size="large" onClick={handleDownloadOnClick}>
                    立即下载
                  </Button>
                </ItemDetail>
              </DesktopInfoItem>

              <DesktopInfoItem>
                <ItemTitle>
                  若您已安装了云远程，请
                  <a href="TRemoteDesk://">点击此处</a>
                </ItemTitle>
                <ItemDetail>*如果浏览器对话弹出，请点击【打开远程控制】按钮</ItemDetail>
              </DesktopInfoItem>
            </DesktopInfo>
          )}
        </RequestContent>
        {!isJoined && !code && (
          <RequestActions>
            <Row justify="center" gutter={10}>
              <Col>
                <Button loading={isLoading} disabled={isLoading} size="large" onClick={() => setIsJoined(true)}>
                  加入远程
                </Button>
              </Col>
              <Col>
                <Button
                  theme="primary"
                  variant="outline"
                  disabled={isLoading}
                  size="large"
                  onClick={handleCopyLinkOnClick}
                >
                  复制邀请
                </Button>
              </Col>
            </Row>
          </RequestActions>
        )}
      </Dialog>
      <OpenClientLink ref={openClientEl} href="TRemoteDesk://">
        点击此处
      </OpenClientLink>
    </WrapperWithBg>
  );
}

export { Request };
