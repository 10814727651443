var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var commonjsGlobal = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
var events$1 = { exports: {} };
var R = typeof Reflect === "object" ? Reflect : null;
var ReflectApply = R && typeof R.apply === "function" ? R.apply : function ReflectApply2(target, receiver, args) {
  return Function.prototype.apply.call(target, receiver, args);
};
var ReflectOwnKeys;
if (R && typeof R.ownKeys === "function") {
  ReflectOwnKeys = R.ownKeys;
} else if (Object.getOwnPropertySymbols) {
  ReflectOwnKeys = function ReflectOwnKeys2(target) {
    return Object.getOwnPropertyNames(target).concat(Object.getOwnPropertySymbols(target));
  };
} else {
  ReflectOwnKeys = function ReflectOwnKeys2(target) {
    return Object.getOwnPropertyNames(target);
  };
}
function ProcessEmitWarning(warning) {
  if (console && console.warn)
    console.warn(warning);
}
var NumberIsNaN = Number.isNaN || function NumberIsNaN2(value) {
  return value !== value;
};
function EventEmitter() {
  EventEmitter.init.call(this);
}
events$1.exports = EventEmitter;
events$1.exports.once = once2;
EventEmitter.EventEmitter = EventEmitter;
EventEmitter.prototype._events = void 0;
EventEmitter.prototype._eventsCount = 0;
EventEmitter.prototype._maxListeners = void 0;
var defaultMaxListeners = 10;
function checkListener(listener) {
  if (typeof listener !== "function") {
    throw new TypeError('The "listener" argument must be of type Function. Received type ' + typeof listener);
  }
}
Object.defineProperty(EventEmitter, "defaultMaxListeners", {
  enumerable: true,
  get: function() {
    return defaultMaxListeners;
  },
  set: function(arg) {
    if (typeof arg !== "number" || arg < 0 || NumberIsNaN(arg)) {
      throw new RangeError('The value of "defaultMaxListeners" is out of range. It must be a non-negative number. Received ' + arg + ".");
    }
    defaultMaxListeners = arg;
  }
});
EventEmitter.init = function() {
  if (this._events === void 0 || this._events === Object.getPrototypeOf(this)._events) {
    this._events = Object.create(null);
    this._eventsCount = 0;
  }
  this._maxListeners = this._maxListeners || void 0;
};
EventEmitter.prototype.setMaxListeners = function setMaxListeners(n) {
  if (typeof n !== "number" || n < 0 || NumberIsNaN(n)) {
    throw new RangeError('The value of "n" is out of range. It must be a non-negative number. Received ' + n + ".");
  }
  this._maxListeners = n;
  return this;
};
function _getMaxListeners(that) {
  if (that._maxListeners === void 0)
    return EventEmitter.defaultMaxListeners;
  return that._maxListeners;
}
EventEmitter.prototype.getMaxListeners = function getMaxListeners() {
  return _getMaxListeners(this);
};
EventEmitter.prototype.emit = function emit(type) {
  var args = [];
  for (var i = 1; i < arguments.length; i++)
    args.push(arguments[i]);
  var doError = type === "error";
  var events2 = this._events;
  if (events2 !== void 0)
    doError = doError && events2.error === void 0;
  else if (!doError)
    return false;
  if (doError) {
    var er;
    if (args.length > 0)
      er = args[0];
    if (er instanceof Error) {
      throw er;
    }
    var err = new Error("Unhandled error." + (er ? " (" + er.message + ")" : ""));
    err.context = er;
    throw err;
  }
  var handler = events2[type];
  if (handler === void 0)
    return false;
  if (typeof handler === "function") {
    ReflectApply(handler, this, args);
  } else {
    var len = handler.length;
    var listeners2 = arrayClone(handler, len);
    for (var i = 0; i < len; ++i)
      ReflectApply(listeners2[i], this, args);
  }
  return true;
};
function _addListener(target, type, listener, prepend) {
  var m;
  var events2;
  var existing;
  checkListener(listener);
  events2 = target._events;
  if (events2 === void 0) {
    events2 = target._events = Object.create(null);
    target._eventsCount = 0;
  } else {
    if (events2.newListener !== void 0) {
      target.emit("newListener", type, listener.listener ? listener.listener : listener);
      events2 = target._events;
    }
    existing = events2[type];
  }
  if (existing === void 0) {
    existing = events2[type] = listener;
    ++target._eventsCount;
  } else {
    if (typeof existing === "function") {
      existing = events2[type] = prepend ? [listener, existing] : [existing, listener];
    } else if (prepend) {
      existing.unshift(listener);
    } else {
      existing.push(listener);
    }
    m = _getMaxListeners(target);
    if (m > 0 && existing.length > m && !existing.warned) {
      existing.warned = true;
      var w = new Error("Possible EventEmitter memory leak detected. " + existing.length + " " + String(type) + " listeners added. Use emitter.setMaxListeners() to increase limit");
      w.name = "MaxListenersExceededWarning";
      w.emitter = target;
      w.type = type;
      w.count = existing.length;
      ProcessEmitWarning(w);
    }
  }
  return target;
}
EventEmitter.prototype.addListener = function addListener(type, listener) {
  return _addListener(this, type, listener, false);
};
EventEmitter.prototype.on = EventEmitter.prototype.addListener;
EventEmitter.prototype.prependListener = function prependListener(type, listener) {
  return _addListener(this, type, listener, true);
};
function onceWrapper() {
  if (!this.fired) {
    this.target.removeListener(this.type, this.wrapFn);
    this.fired = true;
    if (arguments.length === 0)
      return this.listener.call(this.target);
    return this.listener.apply(this.target, arguments);
  }
}
function _onceWrap(target, type, listener) {
  var state = { fired: false, wrapFn: void 0, target, type, listener };
  var wrapped = onceWrapper.bind(state);
  wrapped.listener = listener;
  state.wrapFn = wrapped;
  return wrapped;
}
EventEmitter.prototype.once = function once(type, listener) {
  checkListener(listener);
  this.on(type, _onceWrap(this, type, listener));
  return this;
};
EventEmitter.prototype.prependOnceListener = function prependOnceListener(type, listener) {
  checkListener(listener);
  this.prependListener(type, _onceWrap(this, type, listener));
  return this;
};
EventEmitter.prototype.removeListener = function removeListener(type, listener) {
  var list, events2, position, i, originalListener;
  checkListener(listener);
  events2 = this._events;
  if (events2 === void 0)
    return this;
  list = events2[type];
  if (list === void 0)
    return this;
  if (list === listener || list.listener === listener) {
    if (--this._eventsCount === 0)
      this._events = Object.create(null);
    else {
      delete events2[type];
      if (events2.removeListener)
        this.emit("removeListener", type, list.listener || listener);
    }
  } else if (typeof list !== "function") {
    position = -1;
    for (i = list.length - 1; i >= 0; i--) {
      if (list[i] === listener || list[i].listener === listener) {
        originalListener = list[i].listener;
        position = i;
        break;
      }
    }
    if (position < 0)
      return this;
    if (position === 0)
      list.shift();
    else {
      spliceOne(list, position);
    }
    if (list.length === 1)
      events2[type] = list[0];
    if (events2.removeListener !== void 0)
      this.emit("removeListener", type, originalListener || listener);
  }
  return this;
};
EventEmitter.prototype.off = EventEmitter.prototype.removeListener;
EventEmitter.prototype.removeAllListeners = function removeAllListeners(type) {
  var listeners2, events2, i;
  events2 = this._events;
  if (events2 === void 0)
    return this;
  if (events2.removeListener === void 0) {
    if (arguments.length === 0) {
      this._events = Object.create(null);
      this._eventsCount = 0;
    } else if (events2[type] !== void 0) {
      if (--this._eventsCount === 0)
        this._events = Object.create(null);
      else
        delete events2[type];
    }
    return this;
  }
  if (arguments.length === 0) {
    var keys = Object.keys(events2);
    var key;
    for (i = 0; i < keys.length; ++i) {
      key = keys[i];
      if (key === "removeListener")
        continue;
      this.removeAllListeners(key);
    }
    this.removeAllListeners("removeListener");
    this._events = Object.create(null);
    this._eventsCount = 0;
    return this;
  }
  listeners2 = events2[type];
  if (typeof listeners2 === "function") {
    this.removeListener(type, listeners2);
  } else if (listeners2 !== void 0) {
    for (i = listeners2.length - 1; i >= 0; i--) {
      this.removeListener(type, listeners2[i]);
    }
  }
  return this;
};
function _listeners(target, type, unwrap) {
  var events2 = target._events;
  if (events2 === void 0)
    return [];
  var evlistener = events2[type];
  if (evlistener === void 0)
    return [];
  if (typeof evlistener === "function")
    return unwrap ? [evlistener.listener || evlistener] : [evlistener];
  return unwrap ? unwrapListeners(evlistener) : arrayClone(evlistener, evlistener.length);
}
EventEmitter.prototype.listeners = function listeners(type) {
  return _listeners(this, type, true);
};
EventEmitter.prototype.rawListeners = function rawListeners(type) {
  return _listeners(this, type, false);
};
EventEmitter.listenerCount = function(emitter, type) {
  if (typeof emitter.listenerCount === "function") {
    return emitter.listenerCount(type);
  } else {
    return listenerCount.call(emitter, type);
  }
};
EventEmitter.prototype.listenerCount = listenerCount;
function listenerCount(type) {
  var events2 = this._events;
  if (events2 !== void 0) {
    var evlistener = events2[type];
    if (typeof evlistener === "function") {
      return 1;
    } else if (evlistener !== void 0) {
      return evlistener.length;
    }
  }
  return 0;
}
EventEmitter.prototype.eventNames = function eventNames() {
  return this._eventsCount > 0 ? ReflectOwnKeys(this._events) : [];
};
function arrayClone(arr, n) {
  var copy = new Array(n);
  for (var i = 0; i < n; ++i)
    copy[i] = arr[i];
  return copy;
}
function spliceOne(list, index) {
  for (; index + 1 < list.length; index++)
    list[index] = list[index + 1];
  list.pop();
}
function unwrapListeners(arr) {
  var ret = new Array(arr.length);
  for (var i = 0; i < ret.length; ++i) {
    ret[i] = arr[i].listener || arr[i];
  }
  return ret;
}
function once2(emitter, name) {
  return new Promise(function(resolve, reject) {
    function errorListener(err) {
      emitter.removeListener(name, resolver);
      reject(err);
    }
    function resolver() {
      if (typeof emitter.removeListener === "function") {
        emitter.removeListener("error", errorListener);
      }
      resolve([].slice.call(arguments));
    }
    eventTargetAgnosticAddListener(emitter, name, resolver, { once: true });
    if (name !== "error") {
      addErrorHandlerIfEventEmitter(emitter, errorListener, { once: true });
    }
  });
}
function addErrorHandlerIfEventEmitter(emitter, handler, flags) {
  if (typeof emitter.on === "function") {
    eventTargetAgnosticAddListener(emitter, "error", handler, flags);
  }
}
function eventTargetAgnosticAddListener(emitter, name, listener, flags) {
  if (typeof emitter.on === "function") {
    if (flags.once) {
      emitter.once(name, listener);
    } else {
      emitter.on(name, listener);
    }
  } else if (typeof emitter.addEventListener === "function") {
    emitter.addEventListener(name, function wrapListener(arg) {
      if (flags.once) {
        emitter.removeEventListener(name, wrapListener);
      }
      listener(arg);
    });
  } else {
    throw new TypeError('The "emitter" argument must be of type EventEmitter. Received type ' + typeof emitter);
  }
}
var events = events$1.exports;
var getRandomValues;
var rnds8 = new Uint8Array(16);
function rng() {
  if (!getRandomValues) {
    getRandomValues = typeof crypto !== "undefined" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto) || typeof msCrypto !== "undefined" && typeof msCrypto.getRandomValues === "function" && msCrypto.getRandomValues.bind(msCrypto);
    if (!getRandomValues) {
      throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
    }
  }
  return getRandomValues(rnds8);
}
var REGEX = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;
function validate(uuid) {
  return typeof uuid === "string" && REGEX.test(uuid);
}
var byteToHex = [];
for (var i = 0; i < 256; ++i) {
  byteToHex.push((i + 256).toString(16).substr(1));
}
function stringify(arr) {
  var offset = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
  var uuid = (byteToHex[arr[offset + 0]] + byteToHex[arr[offset + 1]] + byteToHex[arr[offset + 2]] + byteToHex[arr[offset + 3]] + "-" + byteToHex[arr[offset + 4]] + byteToHex[arr[offset + 5]] + "-" + byteToHex[arr[offset + 6]] + byteToHex[arr[offset + 7]] + "-" + byteToHex[arr[offset + 8]] + byteToHex[arr[offset + 9]] + "-" + byteToHex[arr[offset + 10]] + byteToHex[arr[offset + 11]] + byteToHex[arr[offset + 12]] + byteToHex[arr[offset + 13]] + byteToHex[arr[offset + 14]] + byteToHex[arr[offset + 15]]).toLowerCase();
  if (!validate(uuid)) {
    throw TypeError("Stringified UUID is invalid");
  }
  return uuid;
}
function v4(options, buf, offset) {
  options = options || {};
  var rnds = options.random || (options.rng || rng)();
  rnds[6] = rnds[6] & 15 | 64;
  rnds[8] = rnds[8] & 63 | 128;
  if (buf) {
    offset = offset || 0;
    for (var i = 0; i < 16; ++i) {
      buf[offset + i] = rnds[i];
    }
    return buf;
  }
  return stringify(rnds);
}
const MIME_TYPE = {
  JSON: "application/json"
};
const RESPONSE_CODE = {
  SUCCESS: 1e4
};
const DATA_CHANNEL_MESSAGE_TYPE = {
  KEY_MOUSE: 0,
  CUSTOM: 1
};
const KEY_MOUSE_EVENT_TYPE = {
  KEY: 0,
  MOUSE_BUTTON: 1,
  MOUSE_SCROLL: 2,
  MOUSE_MOVE: 3,
  MOUSE_DOUBLE_CLICK: 4,
  CLIP_BOARD: 5,
  CHANGE_SCREEN: 6
};
const CUSTOM_MESSAGE_TYPE = {
  REQUEST_CALL: 0,
  AGREEN_CALL: 1,
  REJECT_CALL: 2,
  HANG_UP_CALL: 3,
  END_CONTROL: 4,
  FIRST_VIDEO_FRAM: 5
};
const KEY_MAP = {
  KeyA: 4,
  KeyB: 5,
  KeyC: 6,
  KeyD: 7,
  KeyE: 8,
  KeyF: 9,
  KeyG: 10,
  KeyH: 11,
  KeyI: 12,
  KeyJ: 13,
  KeyK: 14,
  KeyL: 15,
  KeyM: 16,
  KeyN: 17,
  KeyO: 18,
  KeyP: 19,
  KeyQ: 20,
  KeyR: 21,
  KeyS: 22,
  KeyT: 23,
  KeyU: 24,
  KeyV: 25,
  KeyW: 26,
  KeyX: 27,
  KeyY: 28,
  KeyZ: 29,
  Digit1: 30,
  Digit2: 31,
  Digit3: 32,
  Digit4: 33,
  Digit5: 34,
  Digit6: 35,
  Digit7: 36,
  Digit8: 37,
  Digit9: 38,
  Digit0: 39,
  Enter: 40,
  Escape: 41,
  Backspace: 42,
  Tab: 43,
  Space: 44,
  Minus: 45,
  Equal: 46,
  BracketLeft: 47,
  BracketRight: 48,
  Backslash: 49,
  Semicolon: 51,
  Quote: 52,
  Backquote: 53,
  Comma: 54,
  Period: 55,
  Slash: 56,
  CapsLock: 57,
  F1: 58,
  F2: 59,
  F3: 60,
  F4: 61,
  F5: 62,
  F6: 63,
  F7: 64,
  F8: 65,
  F9: 66,
  F10: 67,
  F11: 68,
  F12: 69,
  PrintScreen: 70,
  ScrollLock: 71,
  Pause: 72,
  Insert: 73,
  Home: 74,
  PageUp: 75,
  Delete: 76,
  End: 77,
  PageDown: 78,
  ArrowRight: 79,
  ArrowLeft: 80,
  ArrowDown: 81,
  ArrowUp: 82,
  NumLock: 83,
  NumpadDivide: 84,
  NumpadMultiply: 85,
  NumpadEnter: 86,
  NumpadPlus: 87,
  NumpadMinus: 88,
  Numpad1: 89,
  Numpad2: 90,
  Numpad3: 91,
  Numpad4: 92,
  Numpad5: 93,
  Numpad6: 94,
  Numpad7: 95,
  Numpad8: 96,
  Numpad9: 97,
  Numpad0: 98,
  NumpadPeriod: 99,
  ContextMenu: 101,
  ControlLeft: 224,
  ShiftLeft: 225,
  AltLeft: 226,
  MetaLeft: 227,
  ControlRight: 228,
  ShiftRight: 229,
  AltRight: 230,
  MetaRight: 231
};
class Call extends events$1.exports.EventEmitter {
  constructor(userid) {
    super();
    this._userid = userid;
    this.status = Call.NEW;
  }
  get userid() {
    return this._userid;
  }
}
Call.NEW = "new";
Call.CONNECTED = "connected";
Call.CALLING_IN = "calling_in";
Call.CALLING_OUT = "calling_out";
Call.DISCONNECTED = "disconnected";
const defaultOptions$1 = {
  bundlePolicy: "max-bundle",
  rtcpMuxPolicy: "require",
  sdpSemantics: "unified-plan"
};
class Peer extends events.EventEmitter {
  constructor(userid) {
    super(userid);
    this._state = "";
    this._forcedClose = false;
    this._callStatus = Call.NEW;
    this.tracks = new Map();
    this.userid = userid;
    this.peerconnection = null;
    this._datachannel = null;
    this._call = null;
    this._lastMsgFromClient = "";
    this.localMidMap = new Map();
  }
  create(options = {}) {
    if (this.peerconnection)
      return this.peerconnection;
    this._forcedClose = false;
    this.peerconnection = new RTCPeerConnection(__spreadProps(__spreadValues({}, defaultOptions$1), {
      options
    }));
    this._setupConnection();
    this._createDatachannel();
  }
  send(data) {
    if (this._datachannel)
      this._datachannel.send(data);
  }
  receiveFirstVideoFrame() {
    this._sendCustomMessage({
      t: CUSTOM_MESSAGE_TYPE.FIRST_VIDEO_FRAM
    });
    return Promise.resolve();
  }
  setupCall(call) {
    this._call = call;
  }
  makeCall() {
    if (!this._call)
      return Promise.resolve("Need to set a call first.");
    if (this._call.status === Call.CALLING_OUT || this._call.status === Call.CALLING_IN)
      return Promise.resolve(this._call);
    this._sendCustomMessage({
      t: CUSTOM_MESSAGE_TYPE.REQUEST_CALL
    });
    this._call.status = Call.CALLING_OUT;
    return Promise.resolve(this._call);
  }
  rejectCall() {
    if (!this._call)
      return Promise.resolve("Need to set a call first.");
    if (this._call.status !== Call.CALLING_IN)
      return Promise.resolve(this._call);
    this._sendCustomMessage({
      t: CUSTOM_MESSAGE_TYPE.REJECT_CALL
    });
    this._call.status = Call.DISCONNECTED;
    return Promise.resolve(this._call);
  }
  answerCall() {
    if (!this._call)
      return Promise.resolve("Need to set a call first.");
    this._sendCustomMessage({
      t: CUSTOM_MESSAGE_TYPE.AGREEN_CALL
    });
    this._call.status = Call.CONNECTED;
    return Promise.resolve(this._call);
  }
  endCall() {
    if (!this._call)
      return Promise.resolve("Need to set a call first.");
    if (this._call.status === Call.NEW || this._call.status === Call.DISCONNECTED)
      return Promise.resolve(this._call);
    this._sendCustomMessage({
      t: CUSTOM_MESSAGE_TYPE.HANG_UP_CALL
    });
    this._call.status = Call.DISCONNECTED;
    return Promise.resolve(this._call);
  }
  _createDatachannel() {
    if (!this.peerconnection) {
      return;
    }
    this._datachannel = this.peerconnection.createDataChannel("dataChannel");
    this._datachannel.onopen = () => {
      console.log("datachannel open");
    };
    this._datachannel.onclose = () => {
      console.log("datachannel close");
    };
    this._datachannel.onmessage = (e) => {
      console.log("datachannel message", e.data);
      const message = JSON.parse(e.data);
      switch (message.t) {
        case DATA_CHANNEL_MESSAGE_TYPE.KEY_MOUSE:
          this._handleKeyMouseEvent(message.m);
          break;
        case DATA_CHANNEL_MESSAGE_TYPE.CUSTOM:
          this._handleCustomMessage(message.m);
          break;
      }
    };
  }
  close() {
    this._forcedClose = true;
    this._sendCustomMessage({
      t: CUSTOM_MESSAGE_TYPE.END_CONTROL
    });
    if (this.peerconnection) {
      this.peerconnection.close();
      this.peerconnection = null;
      this._datachannel = null;
      this.emit(Peer.DISCONNECTED, { forcedClose: this._forcedClose });
    }
  }
  async _handleKeyMouseEvent(message) {
    switch (message.t) {
      case KEY_MOUSE_EVENT_TYPE.CLIP_BOARD:
        try {
          this.__lastMsgFromClient = message.e.t;
          await navigator.clipboard.writeText(message.e.t);
        } catch (e) {
          console.log(e);
        }
        break;
    }
  }
  _handleCustomMessage(message) {
    let callEventType = "";
    console.log("recieved custom message:", message);
    switch (message.t) {
      case CUSTOM_MESSAGE_TYPE.REQUEST_CALL:
        callEventType = Call.CALLING_IN;
        break;
      case CUSTOM_MESSAGE_TYPE.AGREEN_CALL:
        callEventType = Call.CONNECTED;
        break;
      case CUSTOM_MESSAGE_TYPE.REJECT_CALL:
      case CUSTOM_MESSAGE_TYPE.HANG_UP_CALL:
        callEventType = Call.DISCONNECTED;
        break;
    }
    if (callEventType) {
      this._call.status = callEventType;
      this._call.emit("message", callEventType);
    }
  }
  _sendCustomMessage(message) {
    try {
      const data = JSON.stringify({
        t: DATA_CHANNEL_MESSAGE_TYPE.CUSTOM,
        m: message
      });
      this.send(data);
    } catch (e) {
      console.error(e);
    }
  }
  _setupConnection() {
    this.peerconnection.oniceconnectionstatechange = () => {
      const state = this.peerconnection.iceConnectionState;
      this._state = state;
      console.log("peer state change: ", state);
      switch (state) {
        case Peer.NEW:
          this.emit(Peer.NEW);
          break;
        case Peer.CHECKING:
          this.emit(Peer.CHECKING);
          break;
        case Peer.CONNECTED:
        case Peer.COMPLETED:
          this.emit(Peer.CONNECTED);
          break;
        case Peer.FAILED:
        case Peer.DISCONNECTED:
        case Peer.CLOSED:
          this.peerconnection = null;
          this.emit(Peer.DISCONNECTED, { forcedClose: this._forcedClose });
          break;
        default:
          this.emit(state);
      }
    };
  }
}
Peer.NEW = "new";
Peer.CHECKING = "checking";
Peer.CONNECTED = "connected";
Peer.COMPLETED = "completed";
Peer.FAILED = "failed";
Peer.DISCONNECTED = "disconnected";
Peer.CLOSED = "closed";
let defaultOptions = {
  headers: {
    Accept: MIME_TYPE.JSON,
    "Content-Type": MIME_TYPE.JSON
  }
};
const baseRequest = async (url, options) => {
  try {
    let data = null;
    let dataParsed = true;
    const resp = await fetch(url, options);
    const MIMEType = resp.headers.get("Content-Type").split(";")[0];
    switch (MIMEType) {
      case MIME_TYPE.JSON:
        data = await resp.json();
        break;
      default:
        dataParsed = false;
    }
    if (resp.ok) {
      if (dataParsed) {
        if (data.code === RESPONSE_CODE.SUCCESS) {
          return Promise.resolve(data);
        } else {
          return Promise.reject(data);
        }
      } else {
        return Promise.resolve(resp);
      }
    } else {
      return Promise.reject(resp);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};
const post = (url, options) => {
  return baseRequest(url, __spreadProps(__spreadValues(__spreadValues({}, defaultOptions), options), {
    method: "post"
  }));
};
const requestWithRetry = async (url, options, retry = 5, method) => {
  let error = null;
  for (let i = 1; i <= retry; i++) {
    try {
      const resp = await method(url, options);
      return Promise.resolve(resp);
    } catch (e) {
      error = e;
      console.info(`request error ${url}, retry for the ${i} time`);
    }
  }
  if (error)
    return Promise.reject(error);
};
const postWithRerty = async (url, options, retry = 5) => {
  return requestWithRetry(url, options, retry, post);
};
class Signalling extends events.EventEmitter {
  constructor(options) {
    super();
    this.setMaxListeners(Infinity);
    this._httpURL = options.httpURL;
    this._sseURL = options.sseURL;
    this._reconnectInterval = options.reconnectInterval || 2e3;
    this._maxReconnectInterval = options.maxReconnectInterval = 1e4;
    this._reconnectDecay = options.reconnectDecay || 1.5;
    this._options = options;
    this._state = Signalling.NEW;
    this._everConnected = false;
    this._forcedClose = false;
    this._timeOut = false;
    this._sse = null;
    this._sdkappid = null;
    this._roomid = null;
    this._userid = null;
    this._token = null;
    this._reconnectAttempts = 0;
  }
  login(sdkappid, roomid, userid, token, sessionid) {
    if (this._state === Signalling.CONNECTED) {
      return false;
    }
    this._sdkappid = sdkappid;
    this._roomid = roomid;
    this._userid = userid;
    this._token = token;
    this._sessionid = sessionid;
    this._connectChannel();
    return true;
  }
  close() {
    this._forcedClose = true;
    if (this._sse) {
      this._sse.close();
      this._sse = null;
      this._setState(Signalling.DISCONNECTED);
    }
  }
  _setState(state) {
    this._state = state;
    this.emit("state", state);
  }
  async _connectChannel() {
    const params = new URLSearchParams({
      sdkappid: this._sdkappid,
      roomid: this._roomid,
      userid: this._userid,
      token: this._token,
      sessionid: this._sessionid
    });
    const sseURL = this._sseURL + "?" + params.toString();
    console.info("connect signaling with", sseURL, params.toString());
    this._sse = new EventSource(sseURL);
    if (!this._everConnected) {
      this._setState(Signalling.CONNECTING);
    } else {
      this._setState(Signalling.RECONNECTING);
    }
    this._sse.onopen = (ev) => {
      console.info("signaling open", ev);
      this._everConnected = true;
      this._reconnectAttempts = 0;
      this._forcedClose = false;
      this._setState(Signalling.CONNECTED);
    };
    this._sse.onmessage = (ev) => {
      console.info("signaling message: ", ev.data);
      try {
        let msg = JSON.parse(ev.data);
        this.emit("message", msg);
      } catch (error) {
        console.error(error);
      }
    };
    this._sse.onerror = (error) => {
      console.info("signaling error", error);
      if (this._forcedClose)
        return;
      const reconnectTimeout = this._reconnectInterval * Math.pow(this._reconnectDecay, this._reconnectAttempts);
      const timeout = reconnectTimeout > this._maxReconnectInterval ? this._maxReconnectInterval : reconnectTimeout;
      setTimeout(() => {
        this._reconnectAttempts++;
        this._connectChannel();
      }, timeout);
    };
  }
  async send(event, data) {
    const body = {
      token: this._token,
      sdkappid: this._sdkappid,
      roomid: this._roomid,
      userid: this._userid,
      event,
      data
    };
    console.info("send to signaling with: ", body);
    const url = this._httpURL;
    try {
      let resp = await postWithRerty(url, { body: JSON.stringify(body) });
      console.info("send to signaling success: ", resp.data);
      return Promise.resolve(resp);
    } catch (e) {
      console.info("send to signaling error: ", e);
      return Promise.reject(e);
    }
  }
}
Signalling.NEW = "new";
Signalling.DISCONNECTED = "disconnected";
Signalling.CONNECTING = "connecting";
Signalling.CONNECTED = "connected";
Signalling.RECONNECTING = "reconnecting";
class LocalTrack extends events.EventEmitter {
  constructor(userid, mediatype, config, track) {
    super();
    this.setMaxListeners(Infinity);
    this._userid = userid;
    this._mediatype = mediatype;
    this._config = config;
    this._track = track;
    this._sender = null;
    this._transceiver = null;
    this._mediaelement = null;
  }
  get id() {
    return this._track.id;
  }
  get userid() {
    return this._userid;
  }
  get mediatrack() {
    return this._track;
  }
  get mediatype() {
    return this._mediatype;
  }
  async init() {
    if (!this._track) {
      let opt = {};
      if (this._mediatype === "audio") {
        opt = { audio: true };
      } else if (this._mediatype === "video") {
        opt = { video: true };
      } else if (this._mediatype === "screen")
        ;
      const stream = await navigator.mediaDevices.getUserMedia(opt);
      this._track = stream.getTracks()[0];
    }
  }
  async play(mediaelement) {
    this._mediaelement = mediaelement;
    this._mediaelement.setAttribute("playsinline", true);
    this._mediaelement.setAttribute("autoplay", true);
    if (this._mediaelement.srcObject) {
      this._mediaelement.pause();
      this._mediaelement.srcObject.addTrack(this._track);
      this._mediaelement.play();
    } else {
      let stream = new MediaStream();
      stream.addTrack(this._track);
      this._mediaelement.srcObject = stream;
    }
  }
  async stop() {
    this._track.stop();
  }
}
class RemoteTrack extends events.EventEmitter {
  constructor(userid, mediatype, srstrackid) {
    super();
    this.setMaxListeners(Infinity);
    this._userid = userid;
    this._mediatype = mediatype;
    this._track = null;
    this._srctrackid = srstrackid;
  }
  get id() {
    return this._track.id;
  }
  get userid() {
    return this._userid;
  }
  get trackid() {
    return this._srctrackid;
  }
  get mediatype() {
    return this._mediatype;
  }
  async play(mediaelement) {
    this._mediaelement = mediaelement;
    this._mediaelement.setAttribute("playsinline", true);
    this._mediaelement.setAttribute("autoplay", true);
    if (this._mediaelement.srcObject) {
      this._mediaelement.pause();
      this._mediaelement.srcObject.addTrack(this._track);
      this._mediaelement.play();
    } else {
      let stream = new MediaStream();
      stream.addTrack(this._track);
      this._mediaelement.muted = true;
      this._mediaelement.srcObject = stream;
    }
  }
  async close() {
  }
}
function throttle(fn, delay, atleast) {
  let timer = null;
  let previous = null;
  return function() {
    let now = +new Date();
    if (!previous)
      previous = now;
    if (atleast && now - previous > atleast) {
      fn();
      previous = now;
      clearTimeout(timer);
    } else {
      clearTimeout(timer);
      timer = setTimeout(function() {
        fn();
        previous = null;
      }, delay);
    }
  };
}
class Device extends events$1.exports.EventEmitter {
  constructor(element) {
    super(element);
    this._element = null;
    this._elementObserver = null;
    this._elementWidth = 0;
    this._elementHeight = 0;
    this._copyInterval = 0;
    this._lastCopy = "";
    this._handleKeyPress = this._handleKeyPress.bind(this);
    this._handleMouseClick = this._handleMouseClick.bind(this);
    this._handleMouseDoubleClick = this._handleMouseDoubleClick.bind(this);
    this._handleMouseMove = this._handleMouseMove.bind(this);
    this._handleMouseWheel = this._handleMouseWheel.bind(this);
    this._handleCopy = this._handleCopy.bind(this);
    this._handleResize = throttle(this._handleResize.bind(this), 100);
  }
  setup(element) {
    if (!(element instanceof HTMLElement)) {
      console.error("must be html element");
      return;
    }
    this._element = element;
    this._elementWidth = this._element.clientWidth;
    this._elementHeight = this._element.clientHeight;
    this._copyInterval = setInterval(this._handleCopy, 2e3);
    document.addEventListener("keyup", this._handleKeyPress);
    document.addEventListener("keydown", this._handleKeyPress);
    this._element.addEventListener("mousedown", this._handleMouseClick);
    this._element.addEventListener("mouseup", this._handleMouseClick);
    this._element.addEventListener("dbclick", this._handleMouseDoubleClick);
    this._element.addEventListener("mousemove", this._handleMouseMove);
    this._element.addEventListener("wheel", this._handleMouseWheel);
    this._element.addEventListener("contextmenu", this._handleContextMenu);
    this._elementObserver = new ResizeObserver(this._handleResize);
    this._elementObserver.observe(this._element);
  }
  free() {
    if (this._element && this._elementObserver) {
      this._elementObserver.unobserve(this._element);
      this._elementObserver = null;
    }
    if (this._element) {
      this._element.removeEventListener("mousedown", this._handleMouseClick);
      this._element.removeEventListener("mouseup", this._handleMouseClick);
      this._element.removeEventListener("dbclick", this._handleMouseDoubleClick);
      this._element.removeEventListener("mousemove", this._handleMouseMove);
      this._element.removeEventListener("wheel", this._handleMouseWheel);
      this._element.removeEventListener("contextmenu", this._handleContextMenu);
      this._element = null;
    }
    document.removeEventListener("keyup", this._handleKeyPress);
    document.removeEventListener("keydown", this._handleKeyPress);
    document.removeEventListener("copy", this._handleCopy);
    clearInterval(this._copyInterval);
  }
  _handleKeyPress(e) {
    const code = KEY_MAP[e.code];
    const pressed = e.type === "keydown";
    if (code) {
      e.preventDefault();
      const message = {
        t: KEY_MOUSE_EVENT_TYPE.KEY,
        e: {
          p: pressed,
          c: code
        }
      };
      this.emit(Device.INPUT, message);
    }
  }
  _handleMouseClick(e) {
    e.preventDefault();
    const pressed = e.type === "mousedown";
    if (e.button !== 0 && e.button !== 1 && e.button !== 2) {
      return;
    }
    const message = {
      t: KEY_MOUSE_EVENT_TYPE.MOUSE_BUTTON,
      e: {
        p: pressed,
        c: e.button
      }
    };
    this.emit(Device.INPUT, message);
  }
  _handleMouseDoubleClick(e) {
    if (e.button !== 0 && e.button !== 1 || e.button !== 2) {
      return;
    }
    e.preventDefault();
    const message = {
      t: KEY_MOUSE_EVENT_TYPE.MOUSE_DOUBLE_CLICK,
      e: {
        c: e.button
      }
    };
    this.emit(Device.INPUT, message);
  }
  _handleMouseMove(e) {
    e.preventDefault();
    const offsetX = e.offsetX < 0 ? 0 : e.offsetX;
    const offsetY = e.offsetY < 0 ? 0 : e.offsetY;
    const x = offsetX / this._elementWidth;
    const y = offsetY / this._elementHeight;
    const message = {
      t: KEY_MOUSE_EVENT_TYPE.MOUSE_MOVE,
      e: {
        x,
        y
      }
    };
    if (x !== null && y !== null) {
      this.emit(Device.INPUT, message);
    }
  }
  _handleMouseWheel(e) {
    e.preventDefault();
    const message = {
      t: KEY_MOUSE_EVENT_TYPE.MOUSE_SCROLL,
      e: {
        x: e.deltaX / 3 * -1,
        y: e.deltaY / 3 * -1
      }
    };
    this.emit(Device.INPUT, message);
  }
  async _handleCopy() {
    if (!document || !document.hasFocus())
      return;
    try {
      const text = await navigator.clipboard.readText();
      if (text && text !== this._lastCopy) {
        const message = {
          t: KEY_MOUSE_EVENT_TYPE.CLIP_BOARD,
          e: {
            t: text
          }
        };
        this.emit(Device.INPUT, message);
        this._lastCopy = text;
      }
    } catch (e) {
      console.log(e);
    }
  }
  _handleContextMenu(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  _handleResize() {
    this._elementWidth = this._element.clientWidth;
    this._elementHeight = this._element.clientHeight;
  }
}
Device.INPUT = "input";
var lib = {};
var __awaiter = commonjsGlobal && commonjsGlobal.__awaiter || function(thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function(resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function(resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(lib, "__esModule", { value: true });
class AwaitQueue {
  constructor({ ClosedErrorClass = Error, StoppedErrorClass = Error } = {
    ClosedErrorClass: Error,
    StoppedErrorClass: Error
  }) {
    this.closed = false;
    this.pendingTasks = [];
    this.ClosedErrorClass = Error;
    this.StoppedErrorClass = Error;
    this.ClosedErrorClass = ClosedErrorClass;
    this.StoppedErrorClass = StoppedErrorClass;
  }
  get size() {
    return this.pendingTasks.length;
  }
  close() {
    if (this.closed)
      return;
    this.closed = true;
    for (const pendingTask of this.pendingTasks) {
      pendingTask.stopped = true;
      pendingTask.reject(new this.ClosedErrorClass("AwaitQueue closed"));
    }
    this.pendingTasks.length = 0;
  }
  push(task, name) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.closed)
        throw new this.ClosedErrorClass("AwaitQueue closed");
      if (typeof task !== "function")
        throw new TypeError("given task is not a function");
      if (!task.name && name) {
        try {
          Object.defineProperty(task, "name", { value: name });
        } catch (error) {
        }
      }
      return new Promise((resolve, reject) => {
        const pendingTask = {
          task,
          name,
          resolve,
          reject,
          stopped: false,
          enqueuedAt: new Date(),
          executedAt: void 0
        };
        this.pendingTasks.push(pendingTask);
        if (this.pendingTasks.length === 1)
          this.next();
      });
    });
  }
  stop() {
    if (this.closed)
      return;
    for (const pendingTask of this.pendingTasks) {
      pendingTask.stopped = true;
      pendingTask.reject(new this.StoppedErrorClass("AwaitQueue stopped"));
    }
    this.pendingTasks.length = 0;
  }
  dump() {
    const now = new Date();
    return this.pendingTasks.map((pendingTask) => {
      return {
        task: pendingTask.task,
        name: pendingTask.name,
        enqueuedTime: pendingTask.executedAt ? pendingTask.executedAt.getTime() - pendingTask.enqueuedAt.getTime() : now.getTime() - pendingTask.enqueuedAt.getTime(),
        executingTime: pendingTask.executedAt ? now.getTime() - pendingTask.executedAt.getTime() : 0
      };
    });
  }
  next() {
    return __awaiter(this, void 0, void 0, function* () {
      const pendingTask = this.pendingTasks[0];
      if (!pendingTask)
        return;
      yield this.executeTask(pendingTask);
      this.pendingTasks.shift();
      this.next();
    });
  }
  executeTask(pendingTask) {
    return __awaiter(this, void 0, void 0, function* () {
      if (pendingTask.stopped)
        return;
      pendingTask.executedAt = new Date();
      try {
        const result = yield pendingTask.task();
        if (pendingTask.stopped)
          return;
        pendingTask.resolve(result);
      } catch (error) {
        if (pendingTask.stopped)
          return;
        pendingTask.reject(error);
      }
    });
  }
}
var AwaitQueue_1 = lib.AwaitQueue = AwaitQueue;
class Engine extends events.EventEmitter {
  constructor(config) {
    super();
    this.setMaxListeners(Infinity);
    this._state = Engine.NEW;
    this._localpeer = new Peer();
    this._remotepeers = new Map();
    this._device = new Device();
    this._signalling = null;
    this._iceConnected = false;
    this._inputPublished = false;
    this._sdkappid = null;
    this._roomid = null;
    this._userid = null;
    this._token = null;
    this._joinTimes = 0;
    this._queue = new AwaitQueue_1();
    this._config = config;
    this._signalingurl = this._config.signalingurl;
    this._httpurl = this._config.httpurl;
    this._svrtime = this._config.svrtime;
    this._iceserver = this._config.iceserver;
  }
  async join(opt) {
    let { sdkappid, roomid, userid, token } = opt;
    if (this._state === Engine.JOINED) {
      console.info("already joined");
      return Promise.resolve(Engine.JOINED);
    }
    this._sdkappid = sdkappid;
    this._roomid = roomid;
    this._userid = userid;
    this._token = token;
    this._localpeer.userid = userid;
    if (this._sessionid == null) {
      this._sessionid = v4();
    }
    try {
      if (this._signalling) {
        if (this._state === Engine.UN_JOINED) {
          await this._join();
        } else {
          await this._signalling.login(this._sdkappid, this._roomid, this._userid, this._token, this._sessionid);
        }
      } else {
        await this._setupSignaling();
        await this._signalling.login(this._sdkappid, this._roomid, this._userid, this._token, this._sessionid);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async leave() {
    if (this._state === Engine.JOINED) {
      try {
        await this._leave();
      } catch (e) {
        return Promise.reject(e);
      }
    } else {
      return Promise.reject("Not in the room");
    }
  }
  async close() {
    if (this._state === Engine.JOINED) {
      try {
        await this.leave();
      } catch (e) {
        return Promise.reject(e);
      }
    }
    this._signalling.close();
  }
  createLocalTrack(opt) {
    const { userid, mediatype } = opt;
    let track = new LocalTrack(userid, mediatype);
    return track;
  }
  receiveFirstVideoFrame(userid) {
    const remotePeer = this._remotepeers.get(userid);
    if (!remotePeer) {
      return Promise.reject("remote does not exist");
    }
    return remotePeer.receiveFirstVideoFrame();
  }
  createCall(userid) {
    const call = new Call(userid);
    const remotePeer = this._remotepeers.get(call.userid);
    if (!remotePeer) {
      return Promise.reject("remote does not exist");
    }
    remotePeer.setupCall(call);
    return Promise.resolve(call);
  }
  makeCall(call) {
    const remotePeer = this._remotepeers.get(call.userid);
    if (!remotePeer) {
      return Promise.reject("remote does not exist");
    }
    return remotePeer.makeCall(call);
  }
  rejectCall(call) {
    const remotePeer = this._remotepeers.get(call.userid);
    if (!remotePeer) {
      return Promise.reject("remote does not exist");
    }
    return remotePeer.rejectCall(call);
  }
  answerCall(call) {
    const remotePeer = this._remotepeers.get(call.userid);
    if (!remotePeer) {
      return Promise.reject("remote does not exist");
    }
    return remotePeer.answerCall(call);
  }
  endCall(call) {
    const remotePeer = this._remotepeers.get(call.userid);
    if (!remotePeer) {
      return Promise.reject("remote does not exist");
    }
    return remotePeer.endCall(call);
  }
  selectScreen(index, call) {
    const remotePeer = this._remotepeers.get(call.userid);
    if (!remotePeer) {
      return Promise.reject("remote does not exist");
    }
    const data = JSON.stringify({
      t: DATA_CHANNEL_MESSAGE_TYPE.KEY_MOUSE,
      m: {
        t: KEY_MOUSE_EVENT_TYPE.CHANGE_SCREEN,
        e: {
          i: index
        }
      }
    });
    console.log("send select screen:", data);
    remotePeer.send(data);
  }
  publishInput(element, track) {
    if (this._inputPublished)
      return;
    const remotePeer = this._remotepeers.get(track.userid);
    if (!remotePeer) {
      return Promise.reject("remote does not exist");
    }
    this._device.setup(element);
    this._device.on(Device.INPUT, (message) => {
      try {
        const data = JSON.stringify({
          t: DATA_CHANNEL_MESSAGE_TYPE.KEY_MOUSE,
          m: message
        });
        if (message.t !== KEY_MOUSE_EVENT_TYPE.MOUSE_MOVE) {
          console.log("send message", data);
        }
        if (message.t === KEY_MOUSE_EVENT_TYPE.CLIP_BOARD && message.e.t === remotePeer._lastMsgFromClient)
          return;
        remotePeer.send(data);
      } catch (e) {
        console.error(e);
      }
    });
    this._inputPublished = true;
  }
  unpublishInput() {
    if (!this._inputPublished)
      return;
    this._device.free();
    this._device.removeAllListeners();
    this._inputPublished = false;
  }
  async publish(track) {
    if (this._state !== Engine.JOINED) {
      return Promise.reject("Cannot publish a track before joining a room");
    }
    if (!(track instanceof LocalTrack)) {
      return Promise.reject("Cannot publish a track that is not a local track");
    }
    if (this._localpeer.tracks.has(track.id)) {
      return Promise.reject("The track has been published");
    }
    await this._publish(track);
  }
  async unpublish(track) {
    if (!(track instanceof LocalTrack)) {
      return Promise.reject("Cannot publish a track that is not a local track");
    }
    if (!this._localpeer.tracks.has(track.id)) {
      return Promise.reject("Can not unpushlish a track that is not published");
    }
    await this._unpublish(track);
  }
  async subscribe(track) {
    if (this._state !== Engine.JOINED) {
      return Promise.reject("Cannot subscribe a track before joining a room");
    }
    if (!(track instanceof RemoteTrack)) {
      return Promise.reject("Cannot subscribe a track that is not a remote track");
    }
    await this._subscribe(track);
  }
  async unsubscribe(track) {
    const remotePeer = this._remotepeers.get(track.userid);
    if (!(track instanceof RemoteTrack)) {
      return Promise.reject("Cannot unsubscribe a track that is not a remote track");
    }
    if (!remotePeer) {
      return Promise.reject("Cannot unsubscribe a track without a remote peer");
    }
    if (!remotePeer.tracks.has(track.trackid)) {
      return Promise.reject("Can not unsubscribe a track that does not exist");
    }
    await this._unsubscribe(track);
  }
  async _setupSignaling() {
    let opt = {
      httpURL: this._httpurl,
      sseURL: this._signalingurl
    };
    this._signalling = new Signalling(opt);
    this._signalling.on("state", async (state) => {
      if (state === Signalling.CONNECTED) {
        this._setState(Engine.UN_JOINED);
        try {
          await this._join();
        } catch (e) {
          console.error(e);
        }
      } else if (state === Signalling.DISCONNECTED) {
        this._setState(Engine.DISCONNECTED);
      } else if (state === Signalling.CONNECTING) {
        this._setState(Engine.CONNECTING);
      } else if (state === Signalling.RECONNECTING) {
        this._setState(Engine.RECONNECTING);
      }
    });
    this._signalling.on("message", (msg) => {
      if (this._state !== Engine.JOINED) {
        return;
      }
      switch (msg.event) {
        case "trackpublish": {
          this._handleTrackPublish(msg.data);
          break;
        }
        case "trackunpublish": {
          console.log(msg);
          this._handleTrackUnpublish(msg.data);
          break;
        }
        case "userjoin": {
          console.log(msg);
          this._handleUserJoin(msg.data);
          break;
        }
        case "userleave": {
          console.log(msg);
          this._handleUserLeave(msg.data);
          break;
        }
        default: {
          console.log("signalling");
        }
      }
    });
  }
  async _join() {
    if (this._state === Engine.JOINING)
      return;
    this._joinTimes++;
    let data = {
      reconnect: this._joinTimes > 1,
      sessionid: this._sessionid
    };
    console.info("join room");
    this._setState(Engine.JOINING);
    try {
      const resp = await this._signalling.send("join", data);
      console.info("join room success");
      this._setState(Engine.JOINED);
      if (this._joinTimes == 1) {
        this.emit(Engine.RoomJoinedEvent);
      }
      const userlist = resp.data.userlist;
      userlist.forEach((user) => {
        this._handleUserJoin(user);
      });
      userlist.forEach((user) => {
        user.track.forEach((track) => {
          this._handleTrackPublish({
            userid: user.userid,
            mediatype: track.mediatype,
            trackid: track.trackid
          });
        });
      });
    } catch (e) {
      this._setState(Engine.UN_JOINED);
      return Promise.reject(e);
    }
  }
  async _leave() {
    const data = {
      sessionid: this._sessionid
    };
    console.info("leave room with: ", data);
    try {
      const resp = await this._signalling.send("leave", data);
      console.info("leave room success: ", resp);
      this._setState(Engine.UN_JOINED);
      this._sessionid = null;
      this._localpeer.close();
      this._freePeer(this._localpeer);
      this._remotepeers.forEach((remotePeer) => {
        remotePeer.close();
        this._freePeer(remotePeer);
      });
      this._remotepeers.clear();
    } catch (e) {
      return Promise.reject(e);
    }
  }
  async _publish(track) {
    if (!this._localpeer.peerconnection) {
      this._localpeer.create();
      this._setupPeer(this._localpeer);
    }
    const task = async () => {
      const transceiverInit = {
        direction: "sendonly"
      };
      let transceiver = null;
      for (let reused of this._localpeer.peerconnection.getTransceivers()) {
        if (reused.direction === "inactive" && !reused.stopped && reused.mid && this._localpeer.localMidMap.get(reused.mid) === track._track.kind) {
          transceiver = reused;
          transceiver.direction = "sendonly";
          console.info("reuse transceiver", transceiver);
        }
      }
      if (transceiver !== null) {
        track._transceiver = transceiver;
        transceiver.sender.replaceTrack(track._track);
      } else {
        transceiver = this._localpeer.peerconnection.addTransceiver(track._track, transceiverInit);
      }
      track._transceiver = transceiver;
      const offer = await this._localpeer.peerconnection.createOffer();
      await this._localpeer.peerconnection.setLocalDescription(offer);
      this._localpeer.localMidMap.set(transceiver.mid, track._track.kind);
      const data = {
        sdp: offer.sdp,
        track: {
          trackid: track.id,
          aliasid: track.id,
          mediatype: track.mediatype
        }
      };
      console.info("publish track with: ", data);
      try {
        const resp = await this._signalling.send("publish", data);
        const answer = new RTCSessionDescription({
          type: "answer",
          sdp: resp.data.sdp
        });
        console.info("publish track success: ", resp);
        this._localpeer.tracks.set(track.id, track);
        await this._localpeer.peerconnection.setRemoteDescription(answer);
        return Promise.resolve(resp);
      } catch (e) {
        console.info("publish track error: ", e);
        return Promise.reject(e);
      }
    };
    await this._queue.push(task);
  }
  _setupPeer(peer) {
    peer.removeAllListeners();
    peer.on(Peer.DISCONNECTED, ({ forcedClose }) => {
      if (forcedClose)
        return;
      peer.tracks.forEach((track) => {
        if (track instanceof LocalTrack) {
          this._publish(track);
        } else if (track instanceof RemoteTrack) {
          this._subscribe(track);
        }
      });
    });
  }
  _freePeer(peer) {
    peer.removeAllListeners();
  }
  async _unpublish(track) {
    const task = async () => {
      await track._transceiver.sender.replaceTrack(null);
      track._transceiver.direction = "inactive";
      const offer = await this._localpeer.peerconnection.createOffer();
      await this._localpeer.peerconnection.setLocalDescription(offer);
      const data = {
        sdp: offer.sdp,
        track: {
          trackid: track.id
        }
      };
      console.log("unpublish track with: ", data);
      try {
        const resp = await this._signalling.send("unpublish", data);
        const answer = new RTCSessionDescription({
          type: "answer",
          sdp: resp.data.sdp
        });
        console.log("unpublish track success: ", resp);
        this._localpeer.tracks.delete(track.id);
        await this._localpeer.peerconnection.setRemoteDescription(answer);
        return Promise.resolve(resp);
      } catch (e) {
        console.log("unpublish track error: ", e);
        return Promise.reject(e);
      }
    };
    await this._queue.push(task);
  }
  async _subscribe(track) {
    let remotePeer = this._remotepeers.get(track.userid);
    if (!remotePeer) {
      remotePeer = new Peer();
      remotePeer.userid = track.userid;
      this._remotepeers.set(track.userid, remotePeer);
    }
    if (!remotePeer.peerconnection) {
      remotePeer.create();
      this._setupPeer(remotePeer);
    }
    const task = async () => {
      const transceiverInit = {
        direction: "recvonly"
      };
      let kind;
      if (track.mediatype === "audio") {
        kind = "audio";
      } else {
        kind = "video";
      }
      let transceiver = null;
      for (let reused of remotePeer.peerconnection.getTransceivers()) {
        if (reused.direction === "inactive" && !reused.stopped && reused.mid && reused.receiver.track.kind === kind) {
          transceiver = reused;
          transceiver.direction = "recvonly";
          console.info("reuse transceiver", transceiver);
        }
      }
      if (transceiver != null) {
        track._transceiver = transceiver;
      } else {
        track._transceiver = remotePeer.peerconnection.addTransceiver(kind, transceiverInit);
      }
      track._track = track._transceiver.receiver.track;
      const offer = await remotePeer.peerconnection.createOffer();
      await remotePeer.peerconnection.setLocalDescription(offer);
      const data = {
        sdp: offer.sdp,
        track: {
          srcuserid: track.userid,
          srctrackid: track.trackid,
          trackid: track.id
        }
      };
      console.info("subscribe track with: ", data);
      try {
        const resp = await this._signalling.send("subscribe", data);
        const answer = new RTCSessionDescription({
          type: "answer",
          sdp: resp.data.sdp
        });
        console.info("subscribe track success: ", resp);
        remotePeer.tracks.set(track.trackid, track);
        await remotePeer.peerconnection.setRemoteDescription(answer);
      } catch (e) {
        console.info("subscribe track error: ", e);
        return Promise.reject(e);
      }
    };
    await this._queue.push(task);
  }
  async _unsubscribe(track) {
    const remotePeer = this._remotepeers.get(track.userid);
    const task = async () => {
      track._transceiver.direction = "inactive";
      const offer = await remotePeer.peerconnection.createOffer();
      await remotePeer.peerconnection.setLocalDescription(offer);
      const data = {
        sdp: offer.sdp,
        track: {
          srcuserid: track.userid,
          srctrackid: track.trackid,
          trackid: track.id
        }
      };
      console.info("unsubscribe track with: ", data);
      try {
        const resp = await this._signalling.send("unsubscribe", data);
        remotePeer.tracks.delete(track.id);
        console.info("unsubscribe track success: ", resp);
        const answer = new RTCSessionDescription({
          type: "answer",
          sdp: resp.data.sdp
        });
        await remotePeer.peerconnection.setRemoteDescription(answer);
      } catch (e) {
        console.info("unsubscribe track error: ", e);
        return Promise.reject(e);
      }
    };
    await this._queue.push(task);
  }
  _handleTrackPublish(data) {
    const { userid, mediatype, trackid } = data;
    const track = new RemoteTrack(userid, mediatype, trackid);
    const peer = this._remotepeers.get(userid);
    if (!peer) {
      console.error("track publish, remote userid does not exist ", userid);
      return;
    }
    this.emit(Engine.TrackPublishEvent, track);
  }
  _handleTrackUnpublish(data) {
    const { userid, trackid } = data;
    const peer = this._remotepeers.get(userid);
    if (!peer) {
      console.error("track unpublish, remote userid does not exist ", userid);
      return;
    }
    const track = peer.tracks.get(trackid);
    if (!track) {
      console.error("track unpublish, remote trackid does not exist ", trackid);
      return;
    }
    this.emit(Engine.TrackUnpublishEvent, track);
  }
  _handleUserJoin(user) {
    const peer = new Peer();
    peer.userid = user.userid;
    this._remotepeers.set(user.userid, peer);
    this.emit(Engine.UserJoinEvent, { userid: user.userid });
  }
  _handleUserLeave(user) {
    const remotePeer = this._remotepeers.get(user.userid);
    if (!remotePeer) {
      return;
    }
    remotePeer.close();
    this.unpublishInput();
    this._freePeer(remotePeer);
    this._remotepeers.delete(user.userid);
    this.emit(Engine.UserLeaveEvent, { userid: user.userid });
  }
  _setState(state) {
    if (this._state === state) {
      return;
    }
    this._state = state;
    this.emit("state", this._state);
  }
}
Engine.NEW = "new";
Engine.DISCONNECTED = "disconnected";
Engine.DISCONNECTING = "disconnecting";
Engine.CONNECTING = "connecting";
Engine.RECONNECTING = "reconnecting";
Engine.JOINING = "joining";
Engine.JOINED = "joined";
Engine.UN_JOINED = "unjoined";
Engine.TrackPublishEvent = "trackpublish";
Engine.TrackUnpublishEvent = "trackunpublish";
Engine.TrackSubscribeEvent = "tracksubscribe";
Engine.TrackUnsubscribeEvent = "trackunsubscribe";
Engine.JoinEvent = "join";
Engine.UserJoinEvent = "userjoin";
Engine.UserLeaveEvent = "userleave";
export { Call, Engine, LocalTrack, RemoteTrack };
